
[class^="wp-block-uagb"] {

    ul {
        margin-left: 1.5em;
        list-style: none;
        > li {
            &:before {
                content: "";
                border: 5px solid currentColor;
                border-radius: 50%;
                margin-left: -1.5em;
                margin-top: .7em;

                position: absolute;
            }
        }

        &.list-large {
            > li {
                margin-bottom: 2rem;
            }
        }

        &.checklist {
            margin-left: 3em;

            > li {
                margin-bottom: 2em;
                > strong, b {
                    color: $color-green;
                }

                &:before {
                    border: 0;
                    background-image: url('../images/icons/checkmark.png');
                    background-repeat: no-repeat;

                    width: 28px;
                    height: 24px;
                    background-size: cover;

                    margin-top: .2em;
                    margin-left: -3em;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.u-pull-left {
        > .uagb-columns__inner-wrap {
            margin-left: 0;
        }
    }

    .u-primary-heading,
    &.u-primary-heading {
        h3,
        p {
          text-shadow: 0px 2px 20px #102a42;
        }
    }

}


.wp-block-uagb-column,
.wp-block-uagb-columns {
    &.u-d-f {
        .uagb-columns__inner-wrap,
        .uagb-column__inner-wrap {
            @extend .u-d-f;
        }
    }

    &.u-fd-c {
        .uagb-columns__inner-wrap,
        .uagb-column__inner-wrap {
            @extend .u-fd-c;

            height: 100%;
        }
    }
    &.u-jc-c {
        .uagb-columns__inner-wrap,
        .uagb-column__inner-wrap {
            @extend .u-jc-c;
        }
    }

  }

.wp-block-uagb-buttons {
    .uagb-button__link,
    .uagb-button__wrapper,
    .uagb-buttons-repeater {
      &, a {
        text-decoration: none !important;
      }
    }

    &.buttons-arrow {
        .uagb-button__link {
            position: relative;
            padding-left: 32px !important;
            padding-right: 64px !important;


            &:after {
                @extend %button-arrow;
                position: static;
            }
        }
    }

    @media screen and (max-width: 480px) {
        .uagb-buttons-layout-wrap {
           > .wp-block-uagb-buttons-child {
             width: 100%;
           }
        }
    }
}

