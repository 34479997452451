
.avatar-card {
    margin: 90px 75px 15px 15px;
    padding: 10px 15px 30px;
    position: relative;

    max-width: 320px;

    background: #fff;

    &, & > &__name {
        color: $color-blue-dark;
    }

    box-shadow: 0 8px 20px 0 rgba(1, 1, 1, 0.2);

    &__img {
        position: absolute;
        right: -75px;
        top: -75px;

        > img {
            border-radius: 50%;
        }
    }

    & > &__name {
        font-weight: 600;
        line-height: 25px;
        max-width: calc(100% - 75px);
        margin-bottom: .5rem;
    }

    & > &__text {
        margin-bottom: 0;
    }

    @media (max-width: 767px) {
        margin-left: auto;
        margin-right: auto;
    }

}
