.ld-tour-reset {
  // @extend .nt-note-tab;
  text-align: center;
  display: block;
  // color: #fff;
  font-weight: bold;
  // text-decoration: none !important;
  border-bottom: 0 !important;
  font-size: 14px;
  box-shadow: none !important;
  // transition: all ease-in-out 250ms;
  transition: right .4s;

  position: fixed;
  z-index: 9000;

  right: -156px;
  // bottom: auto;

  @media screen and (min-height: 480px) and (min-width: 768px) {
    top: 314px;
  }

  @media screen and (min-height: 720px) and (min-width: 768px) {
    top: 444px;
  }

  @media screen and (max-width: 768px) {
    top: auto;
    bottom: 58px;
  }


  background: $color-grey;

  &,
  &:hover {
    color: $color-black-off;
    text-decoration: none;
    cursor: pointer;
  }

  &__icon,
  &__text {
    display: inline-block;
    padding: 13px 19px 13px 19px;
  }

  &__icon {
    background: rgba(0, 0, 0, 0.1);
  }

  &:hover {
    right: 0;
    opacity: 0.8;
  }
}
