
body.ld-in-focus-mode.admin-bar {
    padding-top: 50px;
}

body.ld-in-focus-mode:not(.admin-bar) .ld-focus .ld-focus-header + .ld-lesson-status {
    padding-top: 50px;
}

body.ld-in-focus-mode {
    font-size: 16px;
}

.learndash-wrapper {

    .ld-alert-success {
        color: #3aad45 !important;
    }

    .ld-alert-danger {
        &, .ld-alert-icon {
          background-color: transparent;
        }

        background-color: transparent;
        color: $color-red;
        border-color: $color-red;
    }

    .ld-tab-content .ld-alert:first-child {
      margin-top: 0;
      margin-bottom: 2em;
    }

    .ld-table-list .ld-table-list-items {
        border-top: 2px solid $color-grey;
    }

    .ld-lesson-topic-list .ld-table-list.ld-topic-list {
        border: 0;
    }

    .ld-table-list .ld-table-list-footer {
        display: none;
    }

    /**
     * FOCUS MODE
    **/

    .ld-focus {
        // &.ld-focus-sidebar-collapsed {
        //     .ld-focus-sidebar-trigger {
        //         .ld-icon {
        //             transform: translateY(-50%) translateX(25%) rotate(180deg);
        //         }
        //     }
        // }

        .ld-focus-sidebar-wrapper {
            background: #3488C4;
        }

        .ld-focus-sidebar {
            // padding-bottom: 0;
            padding-bottom: 75px;

            .ld-focus-sidebar-trigger {
                .ld-icon {
                    transition: transform .2s;
                }
            }

            .ld-course-navigation {
                &-heading {
                    &,
                    .ld-focus-sidebar-trigger {
                        background: $color-blue-dark !important;
                    }
                }

                .ld-lesson-item-section-heading {
                    margin-top: 0;
                    padding-top: .5em;
                    padding-bottom: .5em;
                    // background: hsl(214, 19%, 94%);
                    // background: $color-blue-light;
                    background: #3488C4;
                    color: white;
                }

                .ld-topic-list.ld-table-list {
                    &:before {
                        background: #e6ebf0;
                    }
                }

                .ld-section-wrapper {
                    border-bottom: 1px solid #4293cd;

                    &.ld-is-current-section {
                        .ld-section-item {
                            position: sticky;
                            top: 0;
                            z-index: 99;
                        }
                    }
                }

                .ld-lesson-item {
                    background: #e6ebf0;

                    &.ld-is-current-lesson {
                        // border-right: 7px solid #fff; //hsla(199, 9%, 66%, 0.42);
                        .ld-lesson-title {
                            color: #333 !important;
                        }

                        .ld-lesson-item-is-not-active .ld-lesson-title {
                            font-weight: normal;
                        }

                        background: #fff;

                        .ld-lesson-item-expanded::before {
                            left: 43px;
                            z-index: 10;
                            border-left-width: 7px;
                            border-right-width: 7px;
                            border-top-width: 7px;
                        }

                        .ld-topic-list.ld-table-list:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 16px;
                            right: 28px;
                            border-bottom: 1px solid #e6ebf0;

                        }
                        .ld-topic-list.ld-table-list:before {
                            top: -8px;
                            left: 43px;

                            // background: #e6ebf0;
                            // background: #e6ebf0;
                            background: #e6ebf0;
                            width: 14px;
                            height: 14px;

                        }

                        .ld-topic-list.ld-table-list {
                            background: darken(#fff, .5);
                        }
                    }

                }

                .ld-status-icon .ld-icon-checkmark {
                    font-size: 0.625em;
                    margin-top: 2px;
                }
            }



        }

        .ld-focus-content {
            h1 {
                font-size: 2em;
            }
        }

        .ld-focus-header + {

          .ld-lesson-status,
          .ld-topic-status {
                &, > .ld-breadcrumbs {
                  border-radius: 0;
                }
                > .ld-breadcrumbs {
                    padding-left: 1.5em;
                }
            }
            .ld-topic-status .ld-progress {
                display: none;
            }
        }

        .ld-focus-header .ld-user-menu {
            .ld-user-menu-items a {
                background: $color-blue-light !important;
                &:hover {
                    opacity: 1;
                    background: lighten($color-blue-light, 5) !important;
                }
            }
        }

        .ld-focus-header {
            .ld-progress {
              flex: 1 0 auto;
              align-items: flex-end;
            }

            .ld-content-actions {

              flex: 0 1;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              > .ld-content-action {
                padding-top: 0;
                padding-bottom: 0;
                &.ld-empty {
                  padding: 0;

                }
                .ld-button {
                  white-space: nowrap;
                }
              }

              .sfwd-mark-complete {
                width: auto;
              }

              .ld-content-action {
                flex: 0 1;
              }

            }

            @media screen and (max-width: 768px) {
              .ld-progress {

              }

              .ld-content-actions {
                flex: 100%;
                .ld-content-action {
                  flex: 50%;
                }
              }
            }

            @media screen and (max-width: 640px) {
              .sfwd-mark-complete:after {
                left: unset;
                right: .5em;
              }
            }
        }
    }

    .ld-content-actions {
      padding-left: 0;
      padding-right: 0;
    }

    .ld-breadcrumbs .ld-breadcrumbs-segments span > span {
        font-weight: 700;
        color: $color-grey-dark;
    }

    .ld-lesson-section-heading > a {
        color: inherit;
    }

    .ld-item-list-heading,
    .ld-item-list.ld-lesson-list .ld-lesson-section-heading {
        margin-bottom: 1em;
        font-size: 20px;
        font-weight: bold;
    }

    .ld-tabs + .ld-item-list-heading {
        border-top: 2px solid #e2e7ed;
        padding-top: 1em;

    }
    /**
     * GRID
     */
    &.lds-template-grid-banner.lds-columns-3 {
        .ld-item-list-items, > .ld-lesson-topic-list .ld-table-list-items {
            &:not(#ld-main-course-list) {
                margin-left: -1%;
                margin-right: -1%;

                @media screen and (min-width: 30.0625em) {
                    display: flex;
                    justify-content: flex-start;
                }
                .ld-table-list-item,
                .ld-item-list-item {
                    overflow: hidden;

                    &.ld-table-list-item {
                        margin-left: 1%;
                        margin-right: 1%;
                    }

                    @media screen and (min-width: 30.0625em) {
                        flex-basis: 48%;
                    }

                    @media screen and (min-width: 60em) {
                        flex-basis: 31.33%;
                    }

                    .ld-item-list-item-preview,
                    .ld-table-list-item-preview {
                        padding-bottom: 16px;
                        overflow: hidden;

                        font-size: 14px;

                        .ld-item-name {
                            width: 100%;
                        }

                        .lds-grid-banners-thumbnail {
                            border-top-left-radius: 6px;
                            border-top-right-radius: 6px;
                            top: -1px;
                        }

                        .ld-item-title {

                            .ld-item-components {
                                margin-top: 0;
                                position: absolute;
                                right: 0;
                                top: -40px;
                                padding: 2px 10px;
                                background: hsla(216, 24%, 96%, 1);

                                box-shadow: 0 0 0 5px #fff;
                            }
                        }

                        .ld-item-details {
                            margin-top: 12px;
                        }
                    }

                    .ld-item-list-item-expanded {
                        min-height: auto;
                        height: calc(100% - 250px - 76px);

                        background: #f5f5f5;
                        border-bottom-left-radius: 9px;
                        border-bottom-right-radius: 9px;

                        &:before {
                            width: 14px;
                            height: 14px;
                            top: -7px;
                        }

                        .ld-table-list {
                            .ld-table-list-item-preview {
                                padding: 12px 0;

                                .ld-topic-title {
                                    font-size: 13px;
                                }

                                .ld-status-icon {
                                    font-size: 0.8em;
                                    width: 18px;
                                    height: 18px;
                                }

                                .ld-topic-title {
                                    line-height: 18px;
                                    flex: 1;
                                }

                                .lds-enhanced-meta {
                                    padding-top: .5em;
                                    font-size: 0.9em;
                                    line-height: 18px;
                                    > .lds-meta-item:first-child {
                                        padding-left: 0;
                                    }
                                }

                                .lds-enhanced-meta,
                                .ld-note-icon {
                                    text-align: right;
                                    flex-basis: 100%;
                                }

                                .ld-note-icon {
                                    margin-top: .5em;
                                    color: #777;
                                    background: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ld-button:not(.ld-js-register-account):not(.learndash-link-previous-incomplete):not(.ld-button-transparent) {
        &.ld-button-prev {
            opacity: 0.75;
            // background: rgba( 7c8ea6,
        }
        &.ld-button-next {
            background: rgba(#3aad45, 0.95) !important;

            // background:
        }
    }

    .ld-tabs-content > .ld-tab-content {

        h2, h3, h4, h5 {
            margin-bottom: 1em;
        }

        a {
            text-decoration: underline;
            color: inherit;
            font-weight: 600;
            &:hover {
                color: $color-blue-light;
            }
        }

        %ld-download-list-items,
        ul:not(.blocks-gallery-grid):not(.wp-block-gallery),
        ol:not(.blocks-gallery-grid):not(.wp-block-gallery) {
            margin-bottom: 1.75em;
            padding-left: 1.5em;
            list-style: none;

            > li:before {
                content: "\25A0";
                color: $color-blue-grey;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }

        > p:last-child {
            margin-bottom: 0;
        }

        .learndash_post_ld-embis-section & {
            > p {
                > strong {
                    color: $color-blue-light;
                }
            }
        }
    }


    .lds-grid-banners-thumbnail {

        background-color: $color-blue-light !important;

        &.lds-lesson-thumbnail
            > a {
                display: flex !important;
                justify-content: center;
                align-items: center;
            }

            &, .lds-lesson-thumbnail-title {
                color: #fff;
            }

            .lds-lesson-thumbnail-title {
                display: block;
                font-size: 24px;
                line-height: 32px;
                max-width: 80%;

                &-small {
                    display: block;
                    font-size: 16px;
                    line-height: 24px;
                }
        }
    }

    .learndash_mark_complete_button,
    #learndash_mark_complete_button {
        white-space: nowrap;
        // padding-top: .75em;
        // padding-bottom: .75em;
    }

    .ld-focus-content .learndash_mark_complete_button {
        padding-left: 1.5em;
        padding-right: 3em;
    }

    @media (max-width: 960px) {
      #learndash_mark_complete_button,
      .learndash_mark_complete_button {
        padding-right: 3em;
      }
    }

    @media (max-width: 640px) {
      .ld-content-actions {
        .ld-content-action {

          .ld-button,
          .learndash_mark_complete_button {
            margin-left: 0;
          }
        }
      }
    }

    .lds-meta-item {
      margin-right: 0;
      + .lds-meta-item {
        padding-left: 6px;
      }
      &.lds-content-icon {
          width: 1em;
          height: 1em;
          background: none;

          svg {
            width: 12px !important;
          }
      }
    }
}

.learndash-not-available .ld-lesson-section-heading,
.user_has_no_access .ld-lesson-section-heading {
    cursor: not-allowed;
    pointer-events: none;
}

.ld-download-list {
    margin-bottom: 2em;
    &:last-child {
        margin-bottom: 0;
    }
    .ld-download-list-heading {
        margin-bottom: 1em;
        font-weight: 600;
        color: $color-blue-light;
    }
    .ld-download-list-items {

        &, .learndash-wrapper .ld-focus .ld-tab-content & {
            &.ld-download-list-items {
                @extend %ld-download-list-items;
            }
        }
    }
    .ld-download-list-item {
        margin: .5em 0;

        a {
            &:hover,
            &:focus {
                text-decoration: underline;
                // color: $color-link-hover;
            }
        }
    }

}

// .ld-script {
//     margin-top: 2em;
//     display: flex;
//     flex-direction: row;
//     align-items: center;

//     border: 2px solid #e2e7ed;
//     padding: 1.5em 2.5em;

//     &__title {
//         flex: 1;
//         font-size: 18px;
//         color: darken(#7c8ea6, 10);
//         font-weight: 700;
//     }
//     &__cta {
//         display: inline-block;
//         padding: .5em 1.5em;
//         // line-height: 20px;
//         &.ld-script__cta {
//             border: 2px solid #4a80ec !important;
//         }
//     }
// }

.ld-script {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;

    border: 2px solid #e2e7ed;
    padding: 1.5em 2.5em;

    &__title {
        flex: 1;
        font-size: 18px;
        color: darken(#7c8ea6, 10);
        font-weight: 700;
    }
    &__cta {
        display: inline-block;
        padding: .5em 1.5em;
        // line-height: 20px;
        &.ld-script__cta {
            border: 2px solid #4a80ec !important;
        }
    }
}

.ld-lesson-item.ld-is-current-lesson .ld-quiz-incomplete svg,
.ld-lesson-item.ld-is-current-lesson .ld-status-icon.ld-status-incomplete svg {
    color: #7c8ea6;
}
.ld-quiz-incomplete.ld-status-icon svg {
    color: #e2e7ed;
}


// profile
.learndash-wrapper {

    .ld-profile-summary {
        margin-top: 0;

        .ld-profile-card {
            margin-top: 0;
        }

        .ld-profile-avatar {
            margin-bottom: 1em;
        }

        .ld-item-list-item-expanded .ld-progress {
            margin-bottom: 0;
        }

        .ld-item-list-item-course.ld-expandable .ld-item-list-item-expanded {
            height: auto;
            max-height: unset;
        }

        .ld-item-name {
            font-size: 1.2em;
        }

        .ld-item-list-cta {
            justify-content: center;
        }
    }
}


.learndash-wrapper {
    .wpProQuiz_content {

        .wpProQuiz_button,
        .wpProQuiz_button2 {
          padding-left: 1.5em;
          padding-right: 1.5em;
          background-color: #7c8ea6;
        }

        .wpProQuiz_time_limit {
            margin-bottom: 2em;
            background: #e2e7ed;
            &:before {
              width: auto;
            }

            .wpProQuiz_progress {
              background-color: $color-blue-light !important;
            }
        }

        .wpProQuiz_question_text {
          h3 {
            line-height: 1.5;
            margin-bottom: 12px;
          }
        }
        .wpProQuiz_questionList {

          &[data-type=multiple],
          &[data-type=single] {
              .wpProQuiz_answerCorrect {
                &, &:last-child {
                  background: none;
                }
                label {
                  background: $color-green;
                  border-color: $color-green-dark;
                }
              }
          }
          // &[data-type=multiple] .wpProQuiz_answerCorrect:last-child,
          // &[data-type=single] .wpProQuiz_answerCorrect:last-child,

        }
        .wpProQuiz_questionListItem {
          margin-bottom: 1em;
          label {
            &.is-selected {
              border-color: $color-blue-light;
            }

            &:hover {
                cursor: pointer;
            }

            .wpProQuiz_questionInput {
              margin-right: 1em !important;
              transform: scale(1.5);

            }



            // [data-type=multiple] .
            // input,
            //  .wpProQuiz_questionListItem:last-child input, body .wpProQuiz_content .wpProQuiz_questionList[data-type=single] .wpProQuiz_questionListItem input, body .wpProQuiz_content .wpProQuiz_questionList[data-type=single] .wpProQuiz_questionListItem:last-child input
          }
        }

        .wpProQuiz_response {
          margin: 0 0 3em;
          background: none;
        }
    }
}


.single-sfwd-courses {

    .entry-title {
      margin-bottom: 30px;
      font-size: 32px;
    }

    .ld-course-status {
        .lds-enhanced-meta {
            display: none;
        }
    }
}
