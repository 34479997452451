
.ld-notice-card {
    position: relative;

    margin-left: auto;
    margin-right: auto;

    max-width: 600px;
    text-align: center;

    &__icon {
        // max-height:
        background: $color-green-dark;
        color: white;
        margin-bottom: 3rem;

        border-radius: 50%;

        max-width: 100%;
        width: 250px;
        height: 250px;
        margin-left: auto;
        margin-right: auto;

        // font-size: 120px;

        display: flex;
        align-items: center;
        justify-content: center;

        &--course-completed {
          background: gold;
        }
    }

    &__text {
        > h2 {
            margin-bottom: .5em;
        }
        margin-bottom: 3rem;

        position: relative;
        z-index: 1;
    }

    &__cta {

        .ld-content-actions {
            border-top: 0;
            justify-content: center;
        }

        .ld-content-action-prev {
            display: none;
        }

    }

}
