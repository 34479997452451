.faq {

    &__item {
        margin-bottom: 10px;

        &, &__title, &__body {
            font-size: 1rem;
        }

        &__title {
            margin-bottom: 0 !important;
            padding: 10px 50px 10px 30px;

            background: $color-blue-light;
            color: #fff !important;
            font-weight: 600 !important;
            cursor: pointer;

            position: relative;

            &:after {
                content: "";

                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-50%);

                background-image: url('../images/icons/arrow-down.png');
                background-size: cover;
                width: 22px;
                height: 15px;

                transition: .3s transform ease-in-out;
            }
        }

        &__body {
            display: none;
            padding: 20px 30px;

            background: #fff;

            > *:last-child {
                margin-bottom: 0;
            }
        }

        &.o-accordion--active {
            .faq__item__title {
                &:after {
                    transform: rotate(180deg) translateY(50%);
                }
            }
        }

    }
}
