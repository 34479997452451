
.ast-separate-container {
    .ast-article-post,
    .ast-article-single {

        @media screen and (max-width: 75em) {
            padding: 3.34em 1em;
        }
    }
}

.site-header {
    background-color: #fff;
}

@media (max-width: 768px) {
    .ast-theme-transparent-header #masthead {
        position: static !important;
    }
}

.main-navigation {
    .main-header-menu {
        font-weight: 600;
        > li {
            a {
                color: $color-blue-dark;
            }
        }

        .sub-menu {
          line-height: 1.45;
        }
    }

    .avatar {
        margin-left: 10px;
        border-radius: 50%;
    }
}

.site-footer-before {
    // max-width: 620px;
    margin-left: auto;
    margin-right: auto;

    text-align: center;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;

    .widget {
        display: inline-block;

        // &:first-child {
        //     float: left;
        // }
        // &:last-child {
        //     float: right;
        // }
    }

    position: relative;
    z-index: 1;
    height: 130px;

    > .widget {
        margin-bottom: 0;
    }

    .widget-certificate {
        background: #fff;
        border: 10px solid #fff;
        border-radius: 50%;

        transform: translateY(30px);

        @media screen and (max-width: 767px) {
          max-width: 40%;
        }
    }

    body.kontakt & {
      background: #f6f6f6;
    }
}

.site-footer {
    .footer-adv-overlay {
        padding-top: 150px;
    }
    .ast-footer-overlay {
        padding: 0.25em 0;
    }

    @media screen and (max-width: 767px) {
      .footer-adv-overlay {
        padding: 70px 0;
      }
      // .ast-footer-overlay {
      //     padding: 0.25em 0;
      // }

    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

a {
    &, &:hover {
        // color: $color-blue-dark;
        text-decoration: underline;
    }
}

a.button {
    text-decoration: none;
}


.button-christmas a,
a.button-christmas {
    background-image: url('../images/christmas_bg_th.jpg') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}


.is-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(black, 0.25);
  z-index: 9999;

  color: $color-blue-light;

  .spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%, -50%);
    z-index: 10000;
  }


}


[class^="wp-block-uagb"] ul.icon-list-flex,
.icon-list-flex  {
  @extend .u-d-f;
  @extend .u-list-bare;
  margin-bottom: 0;

  font-size: 1.25em;

  margin-left: 0;

  // @media screen and (min-width: 540px) {
      flex-wrap: wrap;
      justify-content: center;

  // }

  > li {
    margin: 0 1em 1em;
    text-align: center;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

}
