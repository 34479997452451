
.ld-notice-message {
    padding: 1em 2em;
    background-color: $color-grey;
    border-bottom: 1px solid white;

    position: relative;
    display: flex;
    align-items: center;

    > p {
        margin-bottom: 0;
    }

    .ld-icon {
        // vertical-align: -.25em;
        font-size: 1.5em;
        margin-right: 1em;
    }

    &--success {
        background: $color-green-dark;
        color: white;
    }

}
