.simplebar-track {
    &.simplebar-track {
        background: #f6f6f6;
        .simplebar-scrollbar {
            &:before {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                opacity: 1;
                background: #7c8ea6;
                border-radius: 0;
            }
        }
        // &.simplebar-vertical {
        //     width: 15px;
        // }
        &.simplebar-horizontal {
            height: 18px;
            top: 0;

            > .simplebar-scrollbar {
                margin-top: 0;
                height: 12px;
                top: 3px;
            }
        }


    }
}

.simplebar-wrapper { padding-bottom: 20px}
.simplebar-mask { margin-top: 20px }
