
.u-m-b-none {
    margin-bottom: 0 !important;
}
.u-m-b {
    margin-bottom: 1rem;
}

.u-m-b-d {
  margin-bottom: 28px;
}

.u-m-t {
    margin-top: auto;
}

.cf, .clearfix {
    &:after {
        content: "" !important;
        display: block !important;
        clear: both !important;
    }
}


.u-d-f {
    display: flex;
}

.u-fd-c {
    flex-direction: column;
}

.u-jc-c {
    justify-content: center;
}

%button-arrow {
    content: "";

    position: absolute;
    right: 32px;
    top: 50%;

    display: inline-block;
    width: 0;
    height: 0;
    padding: 6px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: translateY(-50%) rotate(-45deg);
}

.has-text-align-center {
  text-align: center;
}

.has-small-font-size {
  font-size: 13px;
}

.u-list-bare {
  list-style: none;
    > li {
        &:before {
          content: none;
        }
    }
}

.is-96x96 {
  img {
    width: 96px;
    height: auto;
  }
}

@media screen and (min-width: 768px) {
  .hidden-m {
    display: none;
  }
}
