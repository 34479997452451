
.nt-note-tab {
    &.nt-note-tab {
        text-decoration: none;
        right: -110px;
        bottom: auto;
        transition: right .4s;

        @media screen and (min-width: 768px) {
          top: 30vh;
        }

        @media screen and (min-height: 480px) and (min-width: 768px) {
          top: 250px;
        }

        @media screen and (min-height: 720px) and (min-width: 768px) {
          top: 380px;
        }

        @media screen and (max-width: 768px) {
          top: auto;
          bottom: 0;
        }



        &:hover {
            right: 0;
        }
    }

    background: $color-blue-light;


    span {
        padding-right: 20px;
        padding-top: 17px;
        padding-bottom: 17px;
        vertical-align: top;
        line-height: 24px;
    }

    .nticon-doc {
        font-size: 20px;
        padding-top: 9px;
        padding-bottom: 9px;
        padding-bottom: 12px;

    }

}

#nt-note-title-bar {
    background: $color-black-off;
}
#nt-note-title-field, #nt-note-editor-body {
    background: $color-grey-light;
}

input.nt-note-submit {
    background: $color-green-dark;
    color: white;
    border-radius: 0;

    &:hover {
        background: $color-green !important;
        color: white!important;
    }
}

#nt-note-actions li {
    background: $color-grey;
}

#nt-utility-links {
    background: $color-black-off;

    .svg-inline--fa,
    .fa {
        margin-right: 4px;
    }
}


.ldnt-note-filters {
    margin-top: 0;
}

#ldnt-shortcode .ldnt-note-filters {
    margin-top: 0;

    &, input, select {
      font-size: 16px;
    }

    input[type="text"] {
        padding: 10px;
    }

    .ldnt-select-wrap {
        select {
            padding: 10px 40px 10px 10px;
        }

        &::before {
            top: 3px;
        }
    }
}



#ldnt-shortcode table,
table.notes-listing {
    font-size: 16px;

    tbody tr td, thead tr th {
        padding: 10px;
        vertical-align: middle;

        &:first-child {
            padding-right: 0;
            width: 40px;
            text-align: center;
        }
    }

    th {
        background: $color-blue-grey;
    }

    // tr:nth-child(even) td {
    tr:nth-child(even) td {
        background: $color-grey;
    }

    td {
        p {
            margin-bottom: 0;
        }
      }

    .nt-title {
      font-size: 16px;
    }
    .nt-location {
      font-size: 14px;
    }

    tfoot {
        td[colspan="5"] {
            padding: 15px;
            text-align: left;

            .lds-bulk-download {
                background-color: $color-blue-light;
                padding: 10px 20px;
                text-transform: none;
                // font-size
            }
        }
    }

}
