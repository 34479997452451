
.tns-outer {

    .tns-nav {
        margin-top: 32px;
        text-align: left;

        button {
            display: inline-block;
            width: 14px;
            height: 14px;

            margin: 5px 6px;
            padding: 0;
            border: 0;

            background: transparent;
            transition: opacity 200ms ease;
            border-radius: 30px;
            border: 2px solid #fff;

            &:first-child {
                margin-left: 0;
            }

            &.tns-nav-active {

                background: #fff;
            }
        }
    }

}
