.trainer-card {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__img {
        // margin-left: auto;
        // margin-right: auto;
        // max-width: 500px;
    }

    & > &__meta {
        flex: 1;

        background: $color-blue-dark;
        color: #fff;
        padding-bottom: 30px;

        text-align: center;

        > * {
            margin-bottom: 0;
        }
    }

    &__meta > &__name {
        display: inline-block;
        width: auto;
        padding: 8px 15px;
        // margin-bottom: 0;

        font-size: 22px;
        font-weight: 600;
        color: $color-blue-light;
        background: #fff;

        transform: translateY(-50%);
        margin-bottom: 10px;

        position: relative;
        z-index: 1;
    }

    &__meta > &__text {
        // transform: translateY(-50%);
        padding: 0 2rem 0;
    }


}


.trainer-card-list {
    .uagb-column__inner-wrap {
        height: 100%;
    }
}
