
.shepherd-element.shepherd-theme-embis {

  box-shadow: 1px 4px darken($color-blue-light, 10);
  border: 3px solid $color-blue-light;

  .shepherd-arrow {
    &:before {
      // border-left: 1px solid rgba($color-blue-light, 1);
      // border-bottom: 1px solid rgba($color-blue-light, 1);
      box-shadow: -1px 1px rgba($color-blue-light, 1),
                  2px -1px #fff,
                  0 4px darken($color-blue-light, 10);
    }
  }

  &[data-popper-placement^=left] {
    box-shadow: -1px 4px darken($color-blue-light, 10);
    > .shepherd-arrow {
      &:before {
        // border-left: 1px solid rgba($color-blue-light, 1);
        // border-bottom: 1px solid rgba($color-blue-light, 1);
        box-shadow: 1px -1px darken($color-blue-light, 10),
                    0px 1px #fff,
                    4px 0px darken($color-blue-light, 10);
      }
    }
  }
  &[data-popper-placement^=bottom] {
    box-shadow: -1px 4px darken($color-blue-light, 10);
    > .shepherd-arrow {
      &:before {
        // border-left: 1px solid rgba($color-blue-light, 1);
        // border-bottom: 1px solid rgba($color-blue-light, 1);
        box-shadow: -2px 1px $color-blue-light,
                    1px 1px #fff,
                    -2px -2px $color-blue-light;
      }
    }
  }

  .shepherd-header {
    background: #f5f5f5;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .shepherd-title {
      font-weight: 600;
  }

  .shepherd-cancel-icon {
    line-height: 1;

    &:hover {
      border-color: transparent;
      background: none;
    }
  }

  .shepherd-button {
    &, &:not(:disabled):hover {
      color: #fff;
    }
  }

}
