
.woocommerce-account {
  h2  {
    text-align: center;
  }
  .woocommerce-ResetPassword,
  .woocommerce-form.woocommerce-form-login {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;

    border-radius: 0;
    background: $color-grey-light;
    padding: 1.5em;

    .woocommerce-form-login__submit {
      margin-top: 14px;
      padding: 12px 32px;
    }
  }
  .woocommerce-ResetPassword {
    .form-row {
      float: none;
      width: 100%;
    }
  }


}

.woocommerce-MyAccount-navigation {
  margin-bottom: 30px;
}

.woocommerce-MyAccount-navigation-link {

    .is-active a,
    a:hover {
        // color:
        background: darken(#fbfbfb, 5);
    }

    a {
        text-decoration: none !important;
    }
}

.woocommerce-MyAccount-content {

  .woocommerce-orders-table__cell {

      &-order-actions {
          .woocommerce-button:not(:last-child) {
            margin-bottom: 6px;
          }
      }
  }

  .woocommerce-customer-details,
  .woocommerce-order-details,
  .woocommerce-order-downloads {
    td:last-child {
      border-right-width: 0;
    }
  }

  .woocommerce-message[role="alert"] a {
    color: $color-red;
  }


  .woocommerce-message,
  .woocommerce-message {
    background: $color-grey;
    // border-top-color: $color-blue-light;
  }

}


.ld-certificate-list {
    display: flex;
    // justify-content: center;

    &__item {
        @media screen and (min-width: 60em) {
            flex-basis: 48%;
            margin-right: 1%;
        }

        padding: 20px;

        display: flex;
        flex-direction: column;

        align-items: center;

        border: 2px solid #e2e7ed;
        border-radius: 12px;

        background: #fff;

        &__icon {
            color: $color-blue-dark;

        }
        &__course {
            flex: 1;
            margin: 20px 0;
            font-weight: 600;
            text-align: center;
        }

    }
}

.form-row.notes #order_comments {
    min-height: 130px;
}

.woocommerce-page.woocommerce-checkout #payment div.form-row, .woocommerce.woocommerce-checkout #payment div.form-row {
    padding-bottom: 0;
}

.payment_box.payment_method_stripe {
    .wc-stripe-error {
        margin-bottom: 0;
    }
}

// .woocommerce_error woocommerce-error wc-stripe-error
.woocommerce-checkout.woocommerce-checkout {

  @media (min-width: 769px) {
    form.checkout_coupon {
      width: 66%;
    }
  }

  table.shop_table.woocommerce-checkout-review-order-table {
    font-size: 16px;

    th, td {
      padding: 8px;
      border-bottom: 0 !important;
    }

    thead tr:first-child {
      td,
      th {
        padding-top: 12px;
      }
    }

    thead th.product-total {
      display: none;
    }

    .product-total {
      vertical-align: top;
    }

    .order-total {
      td,
      th {
        padding-bottom: 12px;
      }
    }

    .variation {
      margin-top: 6px;
      font-size: 14px;
    }

    tr > td:last-child {
      padding-right: 12px;
    }
    .product-total,
    .tax-rate td,
    .tax-total td,
    .order-total td,
    .fee td,
    .cart-subtotal td {
      text-align: right;
    }

    .product-total,
    .cart-subtotal,
    .tax-total  {
      &, td, th, .woocommerce-Price-amount {
          font-weight: 400;
      }
    }

    .cart-subtotal,
    .tax-total {
      font-size: 0.8rem;
    }


  }

  #wc_checkout_add_ons {
    margin: 3px;
    padding: 15px 30px;
    background: $color-grey-light;

    h3 {
      padding-top: 0;
    }

    label.checkbox {
      font-weight: 700;
      > input {
        margin-right: 10px;
      }
    }
  }



  .wc-gzd-place-order .form-row.checkbox-legal,
  .wc-gzd-place-order .form-row.checkbox-legal label,
  .woocommerce form .form-row input + label,
  input + .woocommerce-gzd-legal-checkbox-text {
      line-height: 1.25;
      font-weight: 400;

  }
  .checkbox-newsletter_signup {
    margin-bottom: 1em !important;

    &, label {
        line-height: 1.25;
        &, span {
          font-weight: 400;
        }
    }
  }

  #order_review .legal {
      padding-right: 0;
  }

  form #order_review {
    padding-top: 1em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    // padding-top: 1em;
    box-shadow: 6px 8px 20px 1px #d4d4d4;
    border-width: 2px;
  }

  #place_order {
      width: 100%;
      margin: 1em 0;
  }

  button.button.alt {
    padding-top: 16px;
    padding-bottom: 16px;
  }


}


.woocommerce-order-received.woocommerce-order-received {

    .entry-content {
      margin-left: auto;
      margin-right: auto;
      // max-width: 960px;

      h1 {
        display: none;
      }

      .woocommerce-thankyou-order-received {
        font-size: 24px;
        color: $color-blue-light;
      }

      .woocommerce-order-overview,
      .woocommerce-order {
        text-align: center;
      }

      .woocommerce-order-overview {
        display: flex;

        flex-wrap: wrap;
        padding: 1em 1.5em;
        background: $color-grey-light;

        justify-content: center;
        @media screen and (min-width: 640px) {
        }

        > li {
          margin-bottom: 12px;

          @media screen and (min-width: 900px) {
            margin-bottom: 0;
          }

          &:last-child {
            margin-right: 0;
            padding-right: 0;
          }
        }
      }

      .max-600 {
        margin-top: 50px;
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
      }

    }

}
