
.card {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__heading {
        // margin-bottom: 0 !important;
        font-size: 26px;
        font-weight: 600;

        // background: $color-blue-dark;
        background: $color-blue-light;
        color: white;
        padding: 6px 32px;
    }

    &__icon {
        text-align: center;
        // transform: translate(-50%, 50%);
        margin-top: -50px;
        transform: translateY(50%);

        + .card__heading {
            padding-top: 50px;
        }

    }

    &__body {
        padding: 20px 32px 40px;
        background: $color-blue-light;
        color: white;
        flex: 1;
    }

    &__cta {
        display: block;

        width: 100%;
        padding: 6px 32px;
        background: $color-green;
        color: white;

        font-size: 16px;
        font-weight: 600;

        &,
        &:hover,
        &:focus {
            text-decoration: none;
            color: white;
        }

        &:hover {
            background: $color-green-dark;
        }

        position: relative;
        &:after {
            @extend %button-arrow;
        }

        &--rev {
            text-align: right;
            &.card__cta {
                &:after {
                    right: auto;
                    left: 32px;
                    transform: translateY(-50%) rotate(135deg);
                }
            }
        }
    }
}
