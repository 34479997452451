$max-inner-width: 1144px;

@import "./colors";
@import "./astra";
@import "./notes";
@import "./notices";
@import "./notice-card";
@import "./woocommerce";
@import "./learndash";
@import "./uagb";
@import "./testimonials";
@import "./tabs";
@import "./avatar-card";
@import "./trainer-card";
@import "./table";
@import "./faq";
@import "./card";
@import "./helpers";
@import "./ninjaforms";
@import "./tinyslider";
@import "./simplebar";
@import "./icons";
@import "./shepherd";
@import "./tour";
@import "./embed";
@import "./wp_avatar";
@import "./fireworks";
