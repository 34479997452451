
.testimonials {
    position: relative;

    &:before, &:after {
        font-size: 160px;
        line-height: 80px;
        font-weight: 600;
        // font-family: 'Open Sans';
        font-style: italic;
        color: $color-blue-dark;
        position: absolute;

    }
    &:before {
        content: "»";
        top: -108px;
        right: 100%;
    }
    &:after {
        content: "«";
        bottom: -62px;
        left: 100%;
    }

    blockquote {
        color: #fff;
    }

    @media (max-width: 1300px) {

        &:before, &:after {
            font-size: 80px;
        }
        &:before {
            top: -70px;
            left: 0;
            right: auto;
        }
        &:after {
            bottom: -66px;
            left: auto;
            right: 0;
        }
    }

    @media (min-width: 977px) and (max-width: 1300px) {
        // &:before, &:after {
        //     font-size: 80px;
        // }
        &:before {
            top: -95px;
            // left: 0;
            // right: auto;
        }
        &:after {
            bottom: -86px;
            // left: auto;
            // right: 0;
        }
    }

    @media (max-width: 767px) {
      // &:before {
      //   top: -95px;
      //   // left: 0;
      //   // right: auto;
      // }
      &:after {
          bottom: -50px;
      }
    }
}

.testimonial {
    @extend .clearfix;
    display: flex;
    position: relative;
    margin-bottom: 16px;
    flex: 1;
    // w100%

    &, &__body {
        font-size: 1em;
    }

    &__meta {
        display: flex;
        flex-direction: column;
        width: 33.33%;
        padding-left: 2em;
    }

    &__img {
        display: inline-block;
        width: 100%;
        max-width: 150px;
        box-shadow: 0 6px 15px 5px rgba(1, 1, 1, 0.2);
        float: right;

        margin-top: .5em;
        margin-bottom: 2em;
    }

    &__body {
        border: 0;
        margin: 0;
        padding: 0;

        width: 66.66%;
    }

    &__author {
        font-weight: 600;
        font-style: italic;
        line-height: 30px;

        > p {
            margin-bottom: 0;
        }
    }

    @media (max-width: 920px) {
        flex-wrap: wrap;

        &__body {
            margin-bottom: 1em;
        }

        &__body, &__meta {
            width: 100%;
        }

        &__meta {
            padding-left: 0;
            flex-direction: row;
            align-items: center;
        }

        &__img {
            margin-bottom: 0;
        }

        &__author {
            margin-left: 1em;
        }
    }

    @media (max-width: 1300px) {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

}
