
.o-tabs {
    position: relative;


    &__controls {
        list-style: none;
        margin: 0 0 30px !important;
        padding-top: 20px;

        display: flex;

        flex-direction: row;
        align-items: stretch;

        counter-set: tabs;

        > li {
            counter-increment: tabs;

            &:before {
                content: none !important;
            }

            min-width: 230px;
            // max-width: 230px;
            width: 100%;

            margin-right: 4px;

            display: flex;
            // align-items: center;

            > a {
                display: flex;
                flex-direction: column;
                justify-content: center;

                flex: 1;
                padding: 40px 20px 20px;
                background: #f6f6f6;
                font-weight: 600;
                color: #7c8ea6;
                text-decoration: none;

                position: relative;


                &:before {
                    content: "Modul " counter(tabs);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    padding: 5px 10px;

                    font-size: 0.7rem;

                    background: $color-grey;
                }

                &:after {
                    content: "";

                    position: absolute;
                    bottom: 0;

                    display: inline-block;
                    width: 0;
                    height: 0;
                    bottom: 10px;
                    right: 20px;

                    // left: 50%;
                    // transform: translateX(-50%) rotate(45deg);

                    // border: 6px solid black;
                    // border-left-color: transparent;
                    // border-right-color: transparent;
                    // border-bottom: 0;
                    border: solid #7c8ea6;
                    border-width: 0 4px 4px 0;
                    padding: 6px;
                    transform: rotate(45deg);

                    background: #f6f6f6;

                }

                &.o-tabs__item--active {
                    color: $color-blue-dark;
                    background: #fff;

                    &:after {
                        content: none;
                    }

                }


            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__items {
        font-size: 0;
    }

    &__item {
        display: inline;

        &--active {
            background-color: transparent;
            font-weight: 600;

            overflow: visible;
            position: relative;

            // @include mq(small) {

                &:after {
                    content: "";

                    position: absolute;
                    left: 0;
                    right: 0;
                    background-color: #fff;
                    bottom: -2px;
                    height: 2px;

                    z-index: 1;
                }
            // }

        }

        &__panel {
            display: inline;
            display: inline-block;

            overflow: hidden;
            position: relative;
            width: 0;
            height: 0;

            opacity: 0;
            transition: opacity .3s;

            &--active {
                display: inline;
                opacity: 1;
                height: auto;
                width: auto;
                display: block; //?

                .o-tabs__item__content {
                    opacity: 1;
                    font-size: 1rem;
                }

            }
        }

        &__content {
            display: block;
            float: left;
            width: 100%;

            opacity: 0;
            transition: opacity .3s;

            font-size: 1rem;

            .uagb-column__inner-wrap {
                padding: 10px;
            }

            .uagb-columns__inner-wrap {

                margin-left: auto;
                margin-right: auto;
                max-width: $max-inner-width;

                &.uagb-columns__columns-2 {
                    .wp-block-uagb-column {
                        .uagb-heading-text {
                            text-align: left;
                        }

                        &:first-child {
                            width: 33%;
                        }
                        &:last-child {
                            width: 66%;
                        }

                    }
                  @media screen and (max-width: 767px) {
                    &.uagb-columns-stack-mobile {
                      flex-wrap: wrap;
                      .wp-block-uagb-column {
                          &,
                          &:first-child,
                          &:last-child {
                            width: 100%;
                          }
                        }
                      }
                    }
                  }
            }
        }
    }
}
