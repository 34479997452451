
$color-blue-dark: #173764;
$color-blue-light: #006eb6;
$color-blue-grey: rgb(125, 143, 166);
$color-grey-light: #fafafa;
$color-grey: #eaeaea;
$color-grey-dark: #d9d9d9;
$color-black-off: #585858;
$color-green: #3dc33e;
$color-green-dark: #3aad45;
$color-orange: #f3a610;
$color-red: #e34747;


$color-link-hover: darken( $color-blue-light, 10 );


$colors: (blue-dark, $color-blue-dark),
(blue-light, $color-blue-light),
(blue-grey, $color-blue-grey),
(grey-light, $color-grey-light),
(grey, $color-grey),
(grey-dark, $color-grey-dark),
(black-off, $color-black-off),
(green, $color-green),
(green-dark, $color-green-dark),
(orange, $color-orange),
(red, $color-red),
(text, #444),
(black, #000),
(white, #fff);

@each $name, $color in $colors {
	.has-#{$name}-color {
		color: $color !important;
	}

	.has-#{$name}-background-color {
		background-color: $color;
	}
}


.u-color-white {
    @extend .has-white-color;
}
.u-color-green {
    @extend .has-green-color;
}
