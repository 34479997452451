

.icon-ellipse {
    display: inline-block;
    width: 100px;
    height: 100px;

    background: white;
    border-radius: 50%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);

    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-size: cover;
        // display: block;

        width: 68px;
        height: 68px;
        max-width: 70%;
        max-height: 70%;
    }
}

.icon-presenter {
    &:after {
        background-image: url("../images/icons/presenter.svg");
    }
}

.icon-video {
    &:after {
        background-image: url("../images/icons/video.svg");
    }
}

.icon-module {
    &:after {
        background-image: url("../images/icons/module.svg");
    }
}

.button.button-icon {
    position: relative;
    margin: auto 0 30px 70px;
    padding-left: 16px;

    white-space: nowrap;

    .icon-ellipse {
        position: absolute;
        top: 50%;
        left: -70px;

        width: 80px;
        height: 80px;

        transform: translateY(-50%);
        z-index: 1;
    }

    &:after {
        @extend %button-arrow;
    }

}


.icon {
    &:before {
        content: "";
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-size: cover;
        margin-right: 1rem;

        vertical-align: top;
    }

    &-phone {
&:before {
        background-image: url('../images/icons/phone.svg');
    }
    }
    &-envelope {
&:before {

        background-image: url('../images/icons/envelope.svg');
    }
    }
}



