
.nf-form-cont .nf-form-content {

    button,
    input[type="button"],
    input[type="submit"] {
        padding-left: 32px;
        padding-right: 32px;
        background: $color-blue-light;

        &:hover {
          color: white;
          background: darken($color-blue-light, 10);
        }
    }
}

