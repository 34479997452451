.table-compare {
    table {
        padding-top: 0;
        border-spacing: 10px;
        table-layout: fixed;

        &, th, td {
          border: 0;
        }

        th, td {
            padding: 10px;
            // text-align: center;
            vertical-align: middle;
        }

        th.christmas-cell {
          // position: relative;
          // &:before {
          //   content: "";
          //   position: absolute;
          //   top: -7px;
          //   left: -6px;
          //   right: 0;
          //   background: url('../images/christmas_bg_snow.png') !important;
          //   background-size: cover;
          //   height: 36px;
          //   z-index: -1;
          // }
          // z-index: 0;
          background-image: url('../images/christmas_bg_th.jpg') !important;
          background-size: cover !important;
          background-repeat: no-repeat !important;
          color: $color-blue-dark;
        }

        td.christmas-cell {
            background-image: url('../images/christmas_bg_table.jpg') !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            color: $color-blue-dark;
        }

        ul {
          margin-left: 1.5em;
          &.u-p-l {
            margin-left: 2.5em;
          }
          > li {
              &:before {
                  border-width: 3px;
                  margin-left: -1.2em;
                  margin-top: .8em;
              }
          }
        }

        @media screen and (max-width: 767px) {
            &,
            // thead,
            tbody,
            tfoot,
            tr, th, td {
                display: block !important;
                width: 100%;
            }

            thead th,
            td {
              margin-bottom: 10px;
            }

            thead th {
              display: none !important;
            }

            td:not(:last-child) {
              margin-bottom: 0;
            }

            td[data-title]:before {
              content: attr(data-title);
              display: block;
              font-weight: 600;
              background: lighten($color-blue-dark, 10);

              margin-left: -10px;
              margin-top: -10px;
              margin-right: -10px;
              margin-bottom: 15px;
              padding: 10px;

            }

        }

    }

    tr.gold {
      td {
        background: #c1a71c;
      }
    }

    th.th-has-icon {
        position: relative;
        padding-top: 50px;
        // &:before {
        //     content: '';
        //     display: block;
        //     height: 50px;
        //     background: white;
        // }

        > .icon-ellipse {
            position: absolute;
            top: -50px;
            left: 50%;

            transform: translateX(-50%);
        }
    }

    th, td:first-child {
        background: $color-blue-dark;
        color: #fff;
        font-size: 26px;
        font-weight: 600;
    }

    thead th,
    tfoot td {
      text-align: center;
        &:first-child {
            background: none;
        }
    }

    td {
        background: $color-blue-light;
        color: #fff;
    }

    tfoot {
        td, .btn {
            background: $color-green;
        }
        .btn {
            display: block;
            // background: $color-green;
            color: white;
            font-size: 26px;
            font-weight: 600;

            text-decoration: none;
            text-align: center;
        }
    }

}
