

.ci_editor {

  .ci_img_container {


  }

  .jcrop-holder > div > div {
    border: 2px dashed $color-red;
  }

  .jcrop-vline, .jcrop-hline {
    background: #fff;
  }

  .ci_controls {
    &:before {
      position: absolute;
      left: 0;
      top: .5em;
      max-width: 70%;
      color: white;
      font-size: 14px;
      line-height: 1.4;
      content: "Bitte wählen Sie einen Ausschnitt und bestätigen Sie mit dem Haken.";

    }
  }
}
