@charset "UTF-8";
.has-blue-dark-color {
  color: #173764 !important; }

.has-blue-dark-background-color {
  background-color: #173764; }

.has-blue-light-color {
  color: #006eb6 !important; }

.has-blue-light-background-color {
  background-color: #006eb6; }

.has-blue-grey-color {
  color: #7d8fa6 !important; }

.has-blue-grey-background-color {
  background-color: #7d8fa6; }

.has-grey-light-color {
  color: #fafafa !important; }

.has-grey-light-background-color {
  background-color: #fafafa; }

.has-grey-color {
  color: #eaeaea !important; }

.has-grey-background-color {
  background-color: #eaeaea; }

.has-grey-dark-color {
  color: #d9d9d9 !important; }

.has-grey-dark-background-color {
  background-color: #d9d9d9; }

.has-black-off-color {
  color: #585858 !important; }

.has-black-off-background-color {
  background-color: #585858; }

.has-green-color, .u-color-green {
  color: #3dc33e !important; }

.has-green-background-color {
  background-color: #3dc33e; }

.has-green-dark-color {
  color: #3aad45 !important; }

.has-green-dark-background-color {
  background-color: #3aad45; }

.has-orange-color {
  color: #f3a610 !important; }

.has-orange-background-color {
  background-color: #f3a610; }

.has-red-color {
  color: #e34747 !important; }

.has-red-background-color {
  background-color: #e34747; }

.has-text-color {
  color: #444 !important; }

.has-text-background-color {
  background-color: #444; }

.has-black-color {
  color: #000 !important; }

.has-black-background-color {
  background-color: #000; }

.has-white-color, .u-color-white {
  color: #fff !important; }

.has-white-background-color {
  background-color: #fff; }

@media screen and (max-width: 75em) {
  .ast-separate-container .ast-article-post,
  .ast-separate-container .ast-article-single {
    padding: 3.34em 1em; } }

.site-header {
  background-color: #fff; }

@media (max-width: 768px) {
  .ast-theme-transparent-header #masthead {
    position: static !important; } }

.main-navigation .main-header-menu {
  font-weight: 600; }
  .main-navigation .main-header-menu > li a {
    color: #173764; }
  .main-navigation .main-header-menu .sub-menu {
    line-height: 1.45; }

.main-navigation .avatar {
  margin-left: 10px;
  border-radius: 50%; }

.site-footer-before {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative;
  z-index: 1;
  height: 130px; }
  .site-footer-before .widget {
    display: inline-block; }
  .site-footer-before > .widget {
    margin-bottom: 0; }
  .site-footer-before .widget-certificate {
    background: #fff;
    border: 10px solid #fff;
    border-radius: 50%;
    transform: translateY(30px); }
    @media screen and (max-width: 767px) {
      .site-footer-before .widget-certificate {
        max-width: 40%; } }
  body.kontakt .site-footer-before {
    background: #f6f6f6; }

.site-footer .footer-adv-overlay {
  padding-top: 150px; }

.site-footer .ast-footer-overlay {
  padding: 0.25em 0; }

@media screen and (max-width: 767px) {
  .site-footer .footer-adv-overlay {
    padding: 70px 0; } }

.site-footer a {
  text-decoration: none; }
  .site-footer a:hover {
    text-decoration: underline; }

a, a:hover {
  text-decoration: underline; }

a.button {
  text-decoration: none; }

.button-christmas a,
a.button-christmas {
  background-image: url("../images/christmas_bg_th.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important; }

.is-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 9999;
  color: #006eb6; }
  .is-loading .spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%, -50%);
    z-index: 10000; }

[class^="wp-block-uagb"] ul.icon-list-flex,
.icon-list-flex {
  margin-bottom: 0;
  font-size: 1.25em;
  margin-left: 0;
  flex-wrap: wrap;
  justify-content: center; }
  [class^="wp-block-uagb"] ul.icon-list-flex > li,
  .icon-list-flex > li {
    margin: 0 1em 1em;
    text-align: center; }
    [class^="wp-block-uagb"] ul.icon-list-flex > li:first-child,
    .icon-list-flex > li:first-child {
      margin-left: 0; }
    [class^="wp-block-uagb"] ul.icon-list-flex > li:last-child,
    .icon-list-flex > li:last-child {
      margin-right: 0; }

.nt-note-tab {
  background: #006eb6; }
  .nt-note-tab.nt-note-tab {
    text-decoration: none;
    right: -110px;
    bottom: auto;
    transition: right .4s; }
    @media screen and (min-width: 768px) {
      .nt-note-tab.nt-note-tab {
        top: 30vh; } }
    @media screen and (min-height: 480px) and (min-width: 768px) {
      .nt-note-tab.nt-note-tab {
        top: 250px; } }
    @media screen and (min-height: 720px) and (min-width: 768px) {
      .nt-note-tab.nt-note-tab {
        top: 380px; } }
    @media screen and (max-width: 768px) {
      .nt-note-tab.nt-note-tab {
        top: auto;
        bottom: 0; } }
    .nt-note-tab.nt-note-tab:hover {
      right: 0; }
  .nt-note-tab span {
    padding-right: 20px;
    padding-top: 17px;
    padding-bottom: 17px;
    vertical-align: top;
    line-height: 24px; }
  .nt-note-tab .nticon-doc {
    font-size: 20px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-bottom: 12px; }

#nt-note-title-bar {
  background: #585858; }

#nt-note-title-field, #nt-note-editor-body {
  background: #fafafa; }

input.nt-note-submit {
  background: #3aad45;
  color: white;
  border-radius: 0; }
  input.nt-note-submit:hover {
    background: #3dc33e !important;
    color: white !important; }

#nt-note-actions li {
  background: #eaeaea; }

#nt-utility-links {
  background: #585858; }
  #nt-utility-links .svg-inline--fa,
  #nt-utility-links .fa {
    margin-right: 4px; }

.ldnt-note-filters {
  margin-top: 0; }

#ldnt-shortcode .ldnt-note-filters {
  margin-top: 0; }
  #ldnt-shortcode .ldnt-note-filters, #ldnt-shortcode .ldnt-note-filters input, #ldnt-shortcode .ldnt-note-filters select {
    font-size: 16px; }
  #ldnt-shortcode .ldnt-note-filters input[type="text"] {
    padding: 10px; }
  #ldnt-shortcode .ldnt-note-filters .ldnt-select-wrap select {
    padding: 10px 40px 10px 10px; }
  #ldnt-shortcode .ldnt-note-filters .ldnt-select-wrap::before {
    top: 3px; }

#ldnt-shortcode table,
table.notes-listing {
  font-size: 16px; }
  #ldnt-shortcode table tbody tr td, #ldnt-shortcode table thead tr th,
  table.notes-listing tbody tr td,
  table.notes-listing thead tr th {
    padding: 10px;
    vertical-align: middle; }
    #ldnt-shortcode table tbody tr td:first-child, #ldnt-shortcode table thead tr th:first-child,
    table.notes-listing tbody tr td:first-child,
    table.notes-listing thead tr th:first-child {
      padding-right: 0;
      width: 40px;
      text-align: center; }
  #ldnt-shortcode table th,
  table.notes-listing th {
    background: #7d8fa6; }
  #ldnt-shortcode table tr:nth-child(even) td,
  table.notes-listing tr:nth-child(even) td {
    background: #eaeaea; }
  #ldnt-shortcode table td p,
  table.notes-listing td p {
    margin-bottom: 0; }
  #ldnt-shortcode table .nt-title,
  table.notes-listing .nt-title {
    font-size: 16px; }
  #ldnt-shortcode table .nt-location,
  table.notes-listing .nt-location {
    font-size: 14px; }
  #ldnt-shortcode table tfoot td[colspan="5"],
  table.notes-listing tfoot td[colspan="5"] {
    padding: 15px;
    text-align: left; }
    #ldnt-shortcode table tfoot td[colspan="5"] .lds-bulk-download,
    table.notes-listing tfoot td[colspan="5"] .lds-bulk-download {
      background-color: #006eb6;
      padding: 10px 20px;
      text-transform: none; }

.ld-notice-message {
  padding: 1em 2em;
  background-color: #eaeaea;
  border-bottom: 1px solid white;
  position: relative;
  display: flex;
  align-items: center; }
  .ld-notice-message > p {
    margin-bottom: 0; }
  .ld-notice-message .ld-icon {
    font-size: 1.5em;
    margin-right: 1em; }
  .ld-notice-message--success {
    background: #3aad45;
    color: white; }

.ld-notice-card {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  text-align: center; }
  .ld-notice-card__icon {
    background: #3aad45;
    color: white;
    margin-bottom: 3rem;
    border-radius: 50%;
    max-width: 100%;
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .ld-notice-card__icon--course-completed {
      background: gold; }
  .ld-notice-card__text {
    margin-bottom: 3rem;
    position: relative;
    z-index: 1; }
    .ld-notice-card__text > h2 {
      margin-bottom: .5em; }
  .ld-notice-card__cta .ld-content-actions {
    border-top: 0;
    justify-content: center; }
  .ld-notice-card__cta .ld-content-action-prev {
    display: none; }

.woocommerce-account h2 {
  text-align: center; }

.woocommerce-account .woocommerce-ResetPassword,
.woocommerce-account .woocommerce-form.woocommerce-form-login {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  border-radius: 0;
  background: #fafafa;
  padding: 1.5em; }
  .woocommerce-account .woocommerce-ResetPassword .woocommerce-form-login__submit,
  .woocommerce-account .woocommerce-form.woocommerce-form-login .woocommerce-form-login__submit {
    margin-top: 14px;
    padding: 12px 32px; }

.woocommerce-account .woocommerce-ResetPassword .form-row {
  float: none;
  width: 100%; }

.woocommerce-MyAccount-navigation {
  margin-bottom: 30px; }

.woocommerce-MyAccount-navigation-link .is-active a,
.woocommerce-MyAccount-navigation-link a:hover {
  background: #eeeeee; }

.woocommerce-MyAccount-navigation-link a {
  text-decoration: none !important; }

.woocommerce-MyAccount-content .woocommerce-orders-table__cell-order-actions .woocommerce-button:not(:last-child) {
  margin-bottom: 6px; }

.woocommerce-MyAccount-content .woocommerce-customer-details td:last-child,
.woocommerce-MyAccount-content .woocommerce-order-details td:last-child,
.woocommerce-MyAccount-content .woocommerce-order-downloads td:last-child {
  border-right-width: 0; }

.woocommerce-MyAccount-content .woocommerce-message[role="alert"] a {
  color: #e34747; }

.woocommerce-MyAccount-content .woocommerce-message,
.woocommerce-MyAccount-content .woocommerce-message {
  background: #eaeaea; }

.ld-certificate-list {
  display: flex; }
  .ld-certificate-list__item {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #e2e7ed;
    border-radius: 12px;
    background: #fff; }
    @media screen and (min-width: 60em) {
      .ld-certificate-list__item {
        flex-basis: 48%;
        margin-right: 1%; } }
    .ld-certificate-list__item__icon {
      color: #173764; }
    .ld-certificate-list__item__course {
      flex: 1;
      margin: 20px 0;
      font-weight: 600;
      text-align: center; }

.form-row.notes #order_comments {
  min-height: 130px; }

.woocommerce-page.woocommerce-checkout #payment div.form-row, .woocommerce.woocommerce-checkout #payment div.form-row {
  padding-bottom: 0; }

.payment_box.payment_method_stripe .wc-stripe-error {
  margin-bottom: 0; }

@media (min-width: 769px) {
  .woocommerce-checkout.woocommerce-checkout form.checkout_coupon {
    width: 66%; } }

.woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table {
  font-size: 16px; }
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table th, .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table td {
    padding: 8px;
    border-bottom: 0 !important; }
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table thead tr:first-child td,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table thead tr:first-child th {
    padding-top: 12px; }
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table thead th.product-total {
    display: none; }
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .product-total {
    vertical-align: top; }
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .order-total td,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .order-total th {
    padding-bottom: 12px; }
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .variation {
    margin-top: 6px;
    font-size: 14px; }
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table tr > td:last-child {
    padding-right: 12px; }
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .product-total,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .tax-rate td,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .tax-total td,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .order-total td,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .fee td,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .cart-subtotal td {
    text-align: right; }
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .product-total, .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .product-total td, .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .product-total th, .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .product-total .woocommerce-Price-amount,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .cart-subtotal,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .cart-subtotal td,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .cart-subtotal th,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .cart-subtotal .woocommerce-Price-amount,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .tax-total,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .tax-total td,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .tax-total th,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .tax-total .woocommerce-Price-amount {
    font-weight: 400; }
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .cart-subtotal,
  .woocommerce-checkout.woocommerce-checkout table.shop_table.woocommerce-checkout-review-order-table .tax-total {
    font-size: 0.8rem; }

.woocommerce-checkout.woocommerce-checkout #wc_checkout_add_ons {
  margin: 3px;
  padding: 15px 30px;
  background: #fafafa; }
  .woocommerce-checkout.woocommerce-checkout #wc_checkout_add_ons h3 {
    padding-top: 0; }
  .woocommerce-checkout.woocommerce-checkout #wc_checkout_add_ons label.checkbox {
    font-weight: 700; }
    .woocommerce-checkout.woocommerce-checkout #wc_checkout_add_ons label.checkbox > input {
      margin-right: 10px; }

.woocommerce-checkout.woocommerce-checkout .wc-gzd-place-order .form-row.checkbox-legal,
.woocommerce-checkout.woocommerce-checkout .wc-gzd-place-order .form-row.checkbox-legal label,
.woocommerce-checkout.woocommerce-checkout .woocommerce form .form-row input + label,
.woocommerce-checkout.woocommerce-checkout input + .woocommerce-gzd-legal-checkbox-text {
  line-height: 1.25;
  font-weight: 400; }

.woocommerce-checkout.woocommerce-checkout .checkbox-newsletter_signup {
  margin-bottom: 1em !important; }
  .woocommerce-checkout.woocommerce-checkout .checkbox-newsletter_signup, .woocommerce-checkout.woocommerce-checkout .checkbox-newsletter_signup label {
    line-height: 1.25; }
    .woocommerce-checkout.woocommerce-checkout .checkbox-newsletter_signup, .woocommerce-checkout.woocommerce-checkout .checkbox-newsletter_signup span, .woocommerce-checkout.woocommerce-checkout .checkbox-newsletter_signup label, .woocommerce-checkout.woocommerce-checkout .checkbox-newsletter_signup label span {
      font-weight: 400; }

.woocommerce-checkout.woocommerce-checkout #order_review .legal {
  padding-right: 0; }

.woocommerce-checkout.woocommerce-checkout form #order_review {
  padding-top: 1em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  box-shadow: 6px 8px 20px 1px #d4d4d4;
  border-width: 2px; }

.woocommerce-checkout.woocommerce-checkout #place_order {
  width: 100%;
  margin: 1em 0; }

.woocommerce-checkout.woocommerce-checkout button.button.alt {
  padding-top: 16px;
  padding-bottom: 16px; }

.woocommerce-order-received.woocommerce-order-received .entry-content {
  margin-left: auto;
  margin-right: auto; }
  .woocommerce-order-received.woocommerce-order-received .entry-content h1 {
    display: none; }
  .woocommerce-order-received.woocommerce-order-received .entry-content .woocommerce-thankyou-order-received {
    font-size: 24px;
    color: #006eb6; }
  .woocommerce-order-received.woocommerce-order-received .entry-content .woocommerce-order-overview,
  .woocommerce-order-received.woocommerce-order-received .entry-content .woocommerce-order {
    text-align: center; }
  .woocommerce-order-received.woocommerce-order-received .entry-content .woocommerce-order-overview {
    display: flex;
    flex-wrap: wrap;
    padding: 1em 1.5em;
    background: #fafafa;
    justify-content: center; }
    .woocommerce-order-received.woocommerce-order-received .entry-content .woocommerce-order-overview > li {
      margin-bottom: 12px; }
      @media screen and (min-width: 900px) {
        .woocommerce-order-received.woocommerce-order-received .entry-content .woocommerce-order-overview > li {
          margin-bottom: 0; } }
      .woocommerce-order-received.woocommerce-order-received .entry-content .woocommerce-order-overview > li:last-child {
        margin-right: 0;
        padding-right: 0; }
  .woocommerce-order-received.woocommerce-order-received .entry-content .max-600 {
    margin-top: 50px;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    text-align: left; }

body.ld-in-focus-mode.admin-bar {
  padding-top: 50px; }

body.ld-in-focus-mode:not(.admin-bar) .ld-focus .ld-focus-header + .ld-lesson-status {
  padding-top: 50px; }

body.ld-in-focus-mode {
  font-size: 16px; }

.learndash-wrapper {
  /**
     * FOCUS MODE
    **/
  /**
     * GRID
     */ }
  .learndash-wrapper .ld-alert-success {
    color: #3aad45 !important; }
  .learndash-wrapper .ld-alert-danger {
    background-color: transparent;
    color: #e34747;
    border-color: #e34747; }
    .learndash-wrapper .ld-alert-danger, .learndash-wrapper .ld-alert-danger .ld-alert-icon {
      background-color: transparent; }
  .learndash-wrapper .ld-tab-content .ld-alert:first-child {
    margin-top: 0;
    margin-bottom: 2em; }
  .learndash-wrapper .ld-table-list .ld-table-list-items {
    border-top: 2px solid #eaeaea; }
  .learndash-wrapper .ld-lesson-topic-list .ld-table-list.ld-topic-list {
    border: 0; }
  .learndash-wrapper .ld-table-list .ld-table-list-footer {
    display: none; }
  .learndash-wrapper .ld-focus .ld-focus-sidebar-wrapper {
    background: #3488C4; }
  .learndash-wrapper .ld-focus .ld-focus-sidebar {
    padding-bottom: 75px; }
    .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-focus-sidebar-trigger .ld-icon {
      transition: transform .2s; }
    .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation-heading,
    .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation-heading .ld-focus-sidebar-trigger {
      background: #173764 !important; }
    .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation .ld-lesson-item-section-heading {
      margin-top: 0;
      padding-top: .5em;
      padding-bottom: .5em;
      background: #3488C4;
      color: white; }
    .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation .ld-topic-list.ld-table-list:before {
      background: #e6ebf0; }
    .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation .ld-section-wrapper {
      border-bottom: 1px solid #4293cd; }
      .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation .ld-section-wrapper.ld-is-current-section .ld-section-item {
        position: sticky;
        top: 0;
        z-index: 99; }
    .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation .ld-lesson-item {
      background: #e6ebf0; }
      .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation .ld-lesson-item.ld-is-current-lesson {
        background: #fff; }
        .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation .ld-lesson-item.ld-is-current-lesson .ld-lesson-title {
          color: #333 !important; }
        .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation .ld-lesson-item.ld-is-current-lesson .ld-lesson-item-is-not-active .ld-lesson-title {
          font-weight: normal; }
        .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation .ld-lesson-item.ld-is-current-lesson .ld-lesson-item-expanded::before {
          left: 43px;
          z-index: 10;
          border-left-width: 7px;
          border-right-width: 7px;
          border-top-width: 7px; }
        .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation .ld-lesson-item.ld-is-current-lesson .ld-topic-list.ld-table-list:after {
          content: "";
          position: absolute;
          top: 0;
          left: 16px;
          right: 28px;
          border-bottom: 1px solid #e6ebf0; }
        .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation .ld-lesson-item.ld-is-current-lesson .ld-topic-list.ld-table-list:before {
          top: -8px;
          left: 43px;
          background: #e6ebf0;
          width: 14px;
          height: 14px; }
        .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation .ld-lesson-item.ld-is-current-lesson .ld-topic-list.ld-table-list {
          background: #fefefe; }
    .learndash-wrapper .ld-focus .ld-focus-sidebar .ld-course-navigation .ld-status-icon .ld-icon-checkmark {
      font-size: 0.625em;
      margin-top: 2px; }
  .learndash-wrapper .ld-focus .ld-focus-content h1 {
    font-size: 2em; }
  .learndash-wrapper .ld-focus .ld-focus-header + .ld-lesson-status, .learndash-wrapper .ld-focus .ld-focus-header + .ld-lesson-status > .ld-breadcrumbs,
  .learndash-wrapper .ld-focus .ld-focus-header + .ld-topic-status,
  .learndash-wrapper .ld-focus .ld-focus-header + .ld-topic-status > .ld-breadcrumbs {
    border-radius: 0; }
  .learndash-wrapper .ld-focus .ld-focus-header + .ld-lesson-status > .ld-breadcrumbs,
  .learndash-wrapper .ld-focus .ld-focus-header + .ld-topic-status > .ld-breadcrumbs {
    padding-left: 1.5em; }
  .learndash-wrapper .ld-focus .ld-focus-header + .ld-topic-status .ld-progress {
    display: none; }
  .learndash-wrapper .ld-focus .ld-focus-header .ld-user-menu .ld-user-menu-items a {
    background: #006eb6 !important; }
    .learndash-wrapper .ld-focus .ld-focus-header .ld-user-menu .ld-user-menu-items a:hover {
      opacity: 1;
      background: #007dd0 !important; }
  .learndash-wrapper .ld-focus .ld-focus-header .ld-progress {
    flex: 1 0 auto;
    align-items: flex-end; }
  .learndash-wrapper .ld-focus .ld-focus-header .ld-content-actions {
    flex: 0 1;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .learndash-wrapper .ld-focus .ld-focus-header .ld-content-actions > .ld-content-action {
      padding-top: 0;
      padding-bottom: 0; }
      .learndash-wrapper .ld-focus .ld-focus-header .ld-content-actions > .ld-content-action.ld-empty {
        padding: 0; }
      .learndash-wrapper .ld-focus .ld-focus-header .ld-content-actions > .ld-content-action .ld-button {
        white-space: nowrap; }
    .learndash-wrapper .ld-focus .ld-focus-header .ld-content-actions .sfwd-mark-complete {
      width: auto; }
    .learndash-wrapper .ld-focus .ld-focus-header .ld-content-actions .ld-content-action {
      flex: 0 1; }
  @media screen and (max-width: 768px) {
    .learndash-wrapper .ld-focus .ld-focus-header .ld-content-actions {
      flex: 100%; }
      .learndash-wrapper .ld-focus .ld-focus-header .ld-content-actions .ld-content-action {
        flex: 50%; } }
  @media screen and (max-width: 640px) {
    .learndash-wrapper .ld-focus .ld-focus-header .sfwd-mark-complete:after {
      left: unset;
      right: .5em; } }
  .learndash-wrapper .ld-content-actions {
    padding-left: 0;
    padding-right: 0; }
  .learndash-wrapper .ld-breadcrumbs .ld-breadcrumbs-segments span > span {
    font-weight: 700;
    color: #d9d9d9; }
  .learndash-wrapper .ld-lesson-section-heading > a {
    color: inherit; }
  .learndash-wrapper .ld-item-list-heading,
  .learndash-wrapper .ld-item-list.ld-lesson-list .ld-lesson-section-heading {
    margin-bottom: 1em;
    font-size: 20px;
    font-weight: bold; }
  .learndash-wrapper .ld-tabs + .ld-item-list-heading {
    border-top: 2px solid #e2e7ed;
    padding-top: 1em; }
  .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list), .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) {
    margin-left: -1%;
    margin-right: -1%; }
    @media screen and (min-width: 30.0625em) {
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list), .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) {
        display: flex;
        justify-content: flex-start; } }
    .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item,
    .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item,
    .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item {
      overflow: hidden; }
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item.ld-table-list-item,
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item.ld-table-list-item, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item.ld-table-list-item,
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item.ld-table-list-item {
        margin-left: 1%;
        margin-right: 1%; }
      @media screen and (min-width: 30.0625em) {
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item {
          flex-basis: 48%; } }
      @media screen and (min-width: 60em) {
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item {
          flex-basis: 31.33%; } }
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-preview,
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-table-list-item-preview,
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-preview,
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-table-list-item-preview, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-preview,
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-table-list-item-preview,
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-preview,
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-table-list-item-preview {
        padding-bottom: 16px;
        overflow: hidden;
        font-size: 14px; }
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-preview .ld-item-name,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-table-list-item-preview .ld-item-name,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-preview .ld-item-name,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-table-list-item-preview .ld-item-name, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-preview .ld-item-name,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-table-list-item-preview .ld-item-name,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-preview .ld-item-name,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-table-list-item-preview .ld-item-name {
          width: 100%; }
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-preview .lds-grid-banners-thumbnail,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-table-list-item-preview .lds-grid-banners-thumbnail,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-preview .lds-grid-banners-thumbnail,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-table-list-item-preview .lds-grid-banners-thumbnail, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-preview .lds-grid-banners-thumbnail,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-table-list-item-preview .lds-grid-banners-thumbnail,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-preview .lds-grid-banners-thumbnail,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-table-list-item-preview .lds-grid-banners-thumbnail {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          top: -1px; }
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-preview .ld-item-title .ld-item-components,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-table-list-item-preview .ld-item-title .ld-item-components,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-preview .ld-item-title .ld-item-components,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-table-list-item-preview .ld-item-title .ld-item-components, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-preview .ld-item-title .ld-item-components,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-table-list-item-preview .ld-item-title .ld-item-components,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-preview .ld-item-title .ld-item-components,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-table-list-item-preview .ld-item-title .ld-item-components {
          margin-top: 0;
          position: absolute;
          right: 0;
          top: -40px;
          padding: 2px 10px;
          background: #f2f4f7;
          box-shadow: 0 0 0 5px #fff; }
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-preview .ld-item-details,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-table-list-item-preview .ld-item-details,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-preview .ld-item-details,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-table-list-item-preview .ld-item-details, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-preview .ld-item-details,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-table-list-item-preview .ld-item-details,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-preview .ld-item-details,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-table-list-item-preview .ld-item-details {
          margin-top: 12px; }
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded,
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded,
      .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded {
        min-height: auto;
        height: calc(100% - 250px - 76px);
        background: #f5f5f5;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px; }
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded:before,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded:before, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded:before,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded:before {
          width: 14px;
          height: 14px;
          top: -7px; }
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview,
        .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview {
          padding: 12px 0; }
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-topic-title,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-topic-title, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-topic-title,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-topic-title {
            font-size: 13px; }
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-status-icon,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-status-icon, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-status-icon,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-status-icon {
            font-size: 0.8em;
            width: 18px;
            height: 18px; }
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-topic-title,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-topic-title, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-topic-title,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-topic-title {
            line-height: 18px;
            flex: 1; }
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .lds-enhanced-meta,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .lds-enhanced-meta, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .lds-enhanced-meta,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .lds-enhanced-meta {
            padding-top: .5em;
            font-size: 0.9em;
            line-height: 18px; }
            .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .lds-enhanced-meta > .lds-meta-item:first-child,
            .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .lds-enhanced-meta > .lds-meta-item:first-child, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .lds-enhanced-meta > .lds-meta-item:first-child,
            .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .lds-enhanced-meta > .lds-meta-item:first-child {
              padding-left: 0; }
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .lds-enhanced-meta,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-note-icon,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .lds-enhanced-meta,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-note-icon, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .lds-enhanced-meta,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-note-icon,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .lds-enhanced-meta,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-note-icon {
            text-align: right;
            flex-basis: 100%; }
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-note-icon,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 .ld-item-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-note-icon, .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-table-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-note-icon,
          .learndash-wrapper.lds-template-grid-banner.lds-columns-3 > .ld-lesson-topic-list .ld-table-list-items:not(#ld-main-course-list) .ld-item-list-item .ld-item-list-item-expanded .ld-table-list .ld-table-list-item-preview .ld-note-icon {
            margin-top: .5em;
            color: #777;
            background: none; }
  .learndash-wrapper .ld-button:not(.ld-js-register-account):not(.learndash-link-previous-incomplete):not(.ld-button-transparent).ld-button-prev {
    opacity: 0.75; }
  .learndash-wrapper .ld-button:not(.ld-js-register-account):not(.learndash-link-previous-incomplete):not(.ld-button-transparent).ld-button-next {
    background: rgba(58, 173, 69, 0.95) !important; }
  .learndash-wrapper .ld-tabs-content > .ld-tab-content h2, .learndash-wrapper .ld-tabs-content > .ld-tab-content h3, .learndash-wrapper .ld-tabs-content > .ld-tab-content h4, .learndash-wrapper .ld-tabs-content > .ld-tab-content h5 {
    margin-bottom: 1em; }
  .learndash-wrapper .ld-tabs-content > .ld-tab-content a {
    text-decoration: underline;
    color: inherit;
    font-weight: 600; }
    .learndash-wrapper .ld-tabs-content > .ld-tab-content a:hover {
      color: #006eb6; }
  .learndash-wrapper .ld-tabs-content > .ld-tab-content .ld-download-list .ld-download-list-items, .ld-download-list .learndash-wrapper .ld-tabs-content > .ld-tab-content .ld-download-list-items, .learndash-wrapper .ld-focus .ld-tabs-content > .ld-tab-content .ld-download-list .ld-download-list-items,
  .learndash-wrapper .ld-tabs-content > .ld-tab-content ul:not(.blocks-gallery-grid):not(.wp-block-gallery),
  .learndash-wrapper .ld-tabs-content > .ld-tab-content ol:not(.blocks-gallery-grid):not(.wp-block-gallery) {
    margin-bottom: 1.75em;
    padding-left: 1.5em;
    list-style: none; }
    .learndash-wrapper .ld-tabs-content > .ld-tab-content .ld-download-list .ld-download-list-items > li:before, .ld-download-list .learndash-wrapper .ld-tabs-content > .ld-tab-content .ld-download-list-items > li:before, .learndash-wrapper .ld-focus .ld-tabs-content > .ld-tab-content .ld-download-list .ld-download-list-items > li:before,
    .learndash-wrapper .ld-tabs-content > .ld-tab-content ul:not(.blocks-gallery-grid):not(.wp-block-gallery) > li:before,
    .learndash-wrapper .ld-tabs-content > .ld-tab-content ol:not(.blocks-gallery-grid):not(.wp-block-gallery) > li:before {
      content: "\25A0";
      color: #7d8fa6;
      display: inline-block;
      width: 1em;
      margin-left: -1em; }
  .learndash-wrapper .ld-tabs-content > .ld-tab-content > p:last-child {
    margin-bottom: 0; }
  .learndash_post_ld-embis-section .learndash-wrapper .ld-tabs-content > .ld-tab-content > p > strong {
    color: #006eb6; }
  .learndash-wrapper .lds-grid-banners-thumbnail {
    background-color: #006eb6 !important; }
    .learndash-wrapper .lds-grid-banners-thumbnail.lds-lesson-thumbnail > a {
      display: flex !important;
      justify-content: center;
      align-items: center; }
    .learndash-wrapper .lds-grid-banners-thumbnail, .learndash-wrapper .lds-grid-banners-thumbnail .lds-lesson-thumbnail-title {
      color: #fff; }
    .learndash-wrapper .lds-grid-banners-thumbnail .lds-lesson-thumbnail-title {
      display: block;
      font-size: 24px;
      line-height: 32px;
      max-width: 80%; }
      .learndash-wrapper .lds-grid-banners-thumbnail .lds-lesson-thumbnail-title-small {
        display: block;
        font-size: 16px;
        line-height: 24px; }
  .learndash-wrapper .learndash_mark_complete_button,
  .learndash-wrapper #learndash_mark_complete_button {
    white-space: nowrap; }
  .learndash-wrapper .ld-focus-content .learndash_mark_complete_button {
    padding-left: 1.5em;
    padding-right: 3em; }
  @media (max-width: 960px) {
    .learndash-wrapper #learndash_mark_complete_button,
    .learndash-wrapper .learndash_mark_complete_button {
      padding-right: 3em; } }
  @media (max-width: 640px) {
    .learndash-wrapper .ld-content-actions .ld-content-action .ld-button,
    .learndash-wrapper .ld-content-actions .ld-content-action .learndash_mark_complete_button {
      margin-left: 0; } }
  .learndash-wrapper .lds-meta-item {
    margin-right: 0; }
    .learndash-wrapper .lds-meta-item + .lds-meta-item {
      padding-left: 6px; }
    .learndash-wrapper .lds-meta-item.lds-content-icon {
      width: 1em;
      height: 1em;
      background: none; }
      .learndash-wrapper .lds-meta-item.lds-content-icon svg {
        width: 12px !important; }

.learndash-not-available .ld-lesson-section-heading,
.user_has_no_access .ld-lesson-section-heading {
  cursor: not-allowed;
  pointer-events: none; }

.ld-download-list {
  margin-bottom: 2em; }
  .ld-download-list:last-child {
    margin-bottom: 0; }
  .ld-download-list .ld-download-list-heading {
    margin-bottom: 1em;
    font-weight: 600;
    color: #006eb6; }
  .ld-download-list .ld-download-list-item {
    margin: .5em 0; }
    .ld-download-list .ld-download-list-item a:hover, .ld-download-list .ld-download-list-item a:focus {
      text-decoration: underline; }

.ld-script {
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid #e2e7ed;
  padding: 1.5em 2.5em; }
  .ld-script__title {
    flex: 1;
    font-size: 18px;
    color: #61748e;
    font-weight: 700; }
  .ld-script__cta {
    display: inline-block;
    padding: .5em 1.5em; }
    .ld-script__cta.ld-script__cta {
      border: 2px solid #4a80ec !important; }

.ld-lesson-item.ld-is-current-lesson .ld-quiz-incomplete svg,
.ld-lesson-item.ld-is-current-lesson .ld-status-icon.ld-status-incomplete svg {
  color: #7c8ea6; }

.ld-quiz-incomplete.ld-status-icon svg {
  color: #e2e7ed; }

.learndash-wrapper .ld-profile-summary {
  margin-top: 0; }
  .learndash-wrapper .ld-profile-summary .ld-profile-card {
    margin-top: 0; }
  .learndash-wrapper .ld-profile-summary .ld-profile-avatar {
    margin-bottom: 1em; }
  .learndash-wrapper .ld-profile-summary .ld-item-list-item-expanded .ld-progress {
    margin-bottom: 0; }
  .learndash-wrapper .ld-profile-summary .ld-item-list-item-course.ld-expandable .ld-item-list-item-expanded {
    height: auto;
    max-height: unset; }
  .learndash-wrapper .ld-profile-summary .ld-item-name {
    font-size: 1.2em; }
  .learndash-wrapper .ld-profile-summary .ld-item-list-cta {
    justify-content: center; }

.learndash-wrapper .wpProQuiz_content .wpProQuiz_button,
.learndash-wrapper .wpProQuiz_content .wpProQuiz_button2 {
  padding-left: 1.5em;
  padding-right: 1.5em;
  background-color: #7c8ea6; }

.learndash-wrapper .wpProQuiz_content .wpProQuiz_time_limit {
  margin-bottom: 2em;
  background: #e2e7ed; }
  .learndash-wrapper .wpProQuiz_content .wpProQuiz_time_limit:before {
    width: auto; }
  .learndash-wrapper .wpProQuiz_content .wpProQuiz_time_limit .wpProQuiz_progress {
    background-color: #006eb6 !important; }

.learndash-wrapper .wpProQuiz_content .wpProQuiz_question_text h3 {
  line-height: 1.5;
  margin-bottom: 12px; }

.learndash-wrapper .wpProQuiz_content .wpProQuiz_questionList[data-type=multiple] .wpProQuiz_answerCorrect, .learndash-wrapper .wpProQuiz_content .wpProQuiz_questionList[data-type=multiple] .wpProQuiz_answerCorrect:last-child, .learndash-wrapper .wpProQuiz_content .wpProQuiz_questionList[data-type=single] .wpProQuiz_answerCorrect, .learndash-wrapper .wpProQuiz_content .wpProQuiz_questionList[data-type=single] .wpProQuiz_answerCorrect:last-child {
  background: none; }

.learndash-wrapper .wpProQuiz_content .wpProQuiz_questionList[data-type=multiple] .wpProQuiz_answerCorrect label, .learndash-wrapper .wpProQuiz_content .wpProQuiz_questionList[data-type=single] .wpProQuiz_answerCorrect label {
  background: #3dc33e;
  border-color: #3aad45; }

.learndash-wrapper .wpProQuiz_content .wpProQuiz_questionListItem {
  margin-bottom: 1em; }
  .learndash-wrapper .wpProQuiz_content .wpProQuiz_questionListItem label.is-selected {
    border-color: #006eb6; }
  .learndash-wrapper .wpProQuiz_content .wpProQuiz_questionListItem label:hover {
    cursor: pointer; }
  .learndash-wrapper .wpProQuiz_content .wpProQuiz_questionListItem label .wpProQuiz_questionInput {
    margin-right: 1em !important;
    transform: scale(1.5); }

.learndash-wrapper .wpProQuiz_content .wpProQuiz_response {
  margin: 0 0 3em;
  background: none; }

.single-sfwd-courses .entry-title {
  margin-bottom: 30px;
  font-size: 32px; }

.single-sfwd-courses .ld-course-status .lds-enhanced-meta {
  display: none; }

[class^="wp-block-uagb"] ul {
  margin-left: 1.5em;
  list-style: none; }
  [class^="wp-block-uagb"] ul > li:before {
    content: "";
    border: 5px solid currentColor;
    border-radius: 50%;
    margin-left: -1.5em;
    margin-top: .7em;
    position: absolute; }
  [class^="wp-block-uagb"] ul.list-large > li {
    margin-bottom: 2rem; }
  [class^="wp-block-uagb"] ul.checklist {
    margin-left: 3em; }
    [class^="wp-block-uagb"] ul.checklist > li {
      margin-bottom: 2em; }
      [class^="wp-block-uagb"] ul.checklist > li > strong, [class^="wp-block-uagb"] ul.checklist > li b {
        color: #3dc33e; }
      [class^="wp-block-uagb"] ul.checklist > li:before {
        border: 0;
        background-image: url("../images/icons/checkmark.png");
        background-repeat: no-repeat;
        width: 28px;
        height: 24px;
        background-size: cover;
        margin-top: .2em;
        margin-left: -3em; }
      [class^="wp-block-uagb"] ul.checklist > li:last-child {
        margin-bottom: 0; }

[class^="wp-block-uagb"].u-pull-left > .uagb-columns__inner-wrap {
  margin-left: 0; }

[class^="wp-block-uagb"] .u-primary-heading h3,
[class^="wp-block-uagb"] .u-primary-heading p, [class^="wp-block-uagb"].u-primary-heading h3,
[class^="wp-block-uagb"].u-primary-heading p {
  text-shadow: 0px 2px 20px #102a42; }

.wp-block-uagb-column.u-fd-c .uagb-columns__inner-wrap,
.wp-block-uagb-columns.u-fd-c .wp-block-uagb-column.uagb-columns__inner-wrap .uagb-columns__inner-wrap,
.wp-block-uagb-columns.u-fd-c .wp-block-uagb-column.uagb-column__inner-wrap .uagb-columns__inner-wrap,
.wp-block-uagb-column.u-fd-c .uagb-column__inner-wrap,
.wp-block-uagb-columns.u-fd-c .wp-block-uagb-column.uagb-columns__inner-wrap .uagb-column__inner-wrap,
.wp-block-uagb-columns.u-fd-c .wp-block-uagb-column.uagb-column__inner-wrap .uagb-column__inner-wrap,
.wp-block-uagb-columns.u-fd-c .uagb-columns__inner-wrap, .wp-block-uagb-column.u-fd-c .wp-block-uagb-columns.uagb-columns__inner-wrap .uagb-columns__inner-wrap,
.wp-block-uagb-column.u-fd-c .wp-block-uagb-columns.uagb-column__inner-wrap .uagb-columns__inner-wrap,
.wp-block-uagb-columns.u-fd-c .uagb-column__inner-wrap, .wp-block-uagb-column.u-fd-c .wp-block-uagb-columns.uagb-columns__inner-wrap .uagb-column__inner-wrap,
.wp-block-uagb-column.u-fd-c .wp-block-uagb-columns.uagb-column__inner-wrap .uagb-column__inner-wrap {
  height: 100%; }

.wp-block-uagb-buttons .uagb-button__link, .wp-block-uagb-buttons .uagb-button__link a,
.wp-block-uagb-buttons .uagb-button__wrapper,
.wp-block-uagb-buttons .uagb-button__wrapper a,
.wp-block-uagb-buttons .uagb-buttons-repeater,
.wp-block-uagb-buttons .uagb-buttons-repeater a {
  text-decoration: none !important; }

.wp-block-uagb-buttons.buttons-arrow .uagb-button__link {
  position: relative;
  padding-left: 32px !important;
  padding-right: 64px !important; }
  .wp-block-uagb-buttons.buttons-arrow .uagb-button__link:after {
    position: static; }

@media screen and (max-width: 480px) {
  .wp-block-uagb-buttons .uagb-buttons-layout-wrap > .wp-block-uagb-buttons-child {
    width: 100%; } }

.testimonials {
  position: relative; }
  .testimonials:before, .testimonials:after {
    font-size: 160px;
    line-height: 80px;
    font-weight: 600;
    font-style: italic;
    color: #173764;
    position: absolute; }
  .testimonials:before {
    content: "»";
    top: -108px;
    right: 100%; }
  .testimonials:after {
    content: "«";
    bottom: -62px;
    left: 100%; }
  .testimonials blockquote {
    color: #fff; }
  @media (max-width: 1300px) {
    .testimonials:before, .testimonials:after {
      font-size: 80px; }
    .testimonials:before {
      top: -70px;
      left: 0;
      right: auto; }
    .testimonials:after {
      bottom: -66px;
      left: auto;
      right: 0; } }
  @media (min-width: 977px) and (max-width: 1300px) {
    .testimonials:before {
      top: -95px; }
    .testimonials:after {
      bottom: -86px; } }
  @media (max-width: 767px) {
    .testimonials:after {
      bottom: -50px; } }

.testimonial {
  display: flex;
  position: relative;
  margin-bottom: 16px;
  flex: 1; }
  .testimonial, .testimonial__body {
    font-size: 1em; }
  .testimonial__meta {
    display: flex;
    flex-direction: column;
    width: 33.33%;
    padding-left: 2em; }
  .testimonial__img {
    display: inline-block;
    width: 100%;
    max-width: 150px;
    box-shadow: 0 6px 15px 5px rgba(1, 1, 1, 0.2);
    float: right;
    margin-top: .5em;
    margin-bottom: 2em; }
  .testimonial__body {
    border: 0;
    margin: 0;
    padding: 0;
    width: 66.66%; }
  .testimonial__author {
    font-weight: 600;
    font-style: italic;
    line-height: 30px; }
    .testimonial__author > p {
      margin-bottom: 0; }
  @media (max-width: 920px) {
    .testimonial {
      flex-wrap: wrap; }
      .testimonial__body {
        margin-bottom: 1em; }
      .testimonial__body, .testimonial__meta {
        width: 100%; }
      .testimonial__meta {
        padding-left: 0;
        flex-direction: row;
        align-items: center; }
      .testimonial__img {
        margin-bottom: 0; }
      .testimonial__author {
        margin-left: 1em; } }
  @media (max-width: 1300px) {
    .testimonial {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto; } }

.o-tabs {
  position: relative; }
  .o-tabs__controls {
    list-style: none;
    margin: 0 0 30px !important;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    counter-set: tabs; }
    .o-tabs__controls > li {
      counter-increment: tabs;
      min-width: 230px;
      width: 100%;
      margin-right: 4px;
      display: flex; }
      .o-tabs__controls > li:before {
        content: none !important; }
      .o-tabs__controls > li > a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        padding: 40px 20px 20px;
        background: #f6f6f6;
        font-weight: 600;
        color: #7c8ea6;
        text-decoration: none;
        position: relative; }
        .o-tabs__controls > li > a:before {
          content: "Modul " counter(tabs);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          padding: 5px 10px;
          font-size: 0.7rem;
          background: #eaeaea; }
        .o-tabs__controls > li > a:after {
          content: "";
          position: absolute;
          bottom: 0;
          display: inline-block;
          width: 0;
          height: 0;
          bottom: 10px;
          right: 20px;
          border: solid #7c8ea6;
          border-width: 0 4px 4px 0;
          padding: 6px;
          transform: rotate(45deg);
          background: #f6f6f6; }
        .o-tabs__controls > li > a.o-tabs__item--active {
          color: #173764;
          background: #fff; }
          .o-tabs__controls > li > a.o-tabs__item--active:after {
            content: none; }
      .o-tabs__controls > li:last-child {
        margin-right: 0; }
  .o-tabs__items {
    font-size: 0; }
  .o-tabs__item {
    display: inline; }
    .o-tabs__item--active {
      background-color: transparent;
      font-weight: 600;
      overflow: visible;
      position: relative; }
      .o-tabs__item--active:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        background-color: #fff;
        bottom: -2px;
        height: 2px;
        z-index: 1; }
    .o-tabs__item__panel {
      display: inline;
      display: inline-block;
      overflow: hidden;
      position: relative;
      width: 0;
      height: 0;
      opacity: 0;
      transition: opacity .3s; }
      .o-tabs__item__panel--active {
        display: inline;
        opacity: 1;
        height: auto;
        width: auto;
        display: block; }
        .o-tabs__item__panel--active .o-tabs__item__content {
          opacity: 1;
          font-size: 1rem; }
    .o-tabs__item__content {
      display: block;
      float: left;
      width: 100%;
      opacity: 0;
      transition: opacity .3s;
      font-size: 1rem; }
      .o-tabs__item__content .uagb-column__inner-wrap {
        padding: 10px; }
      .o-tabs__item__content .uagb-columns__inner-wrap {
        margin-left: auto;
        margin-right: auto;
        max-width: 1144px; }
        .o-tabs__item__content .uagb-columns__inner-wrap.uagb-columns__columns-2 .wp-block-uagb-column .uagb-heading-text {
          text-align: left; }
        .o-tabs__item__content .uagb-columns__inner-wrap.uagb-columns__columns-2 .wp-block-uagb-column:first-child {
          width: 33%; }
        .o-tabs__item__content .uagb-columns__inner-wrap.uagb-columns__columns-2 .wp-block-uagb-column:last-child {
          width: 66%; }
        @media screen and (max-width: 767px) {
          .o-tabs__item__content .uagb-columns__inner-wrap.uagb-columns__columns-2.uagb-columns-stack-mobile {
            flex-wrap: wrap; }
            .o-tabs__item__content .uagb-columns__inner-wrap.uagb-columns__columns-2.uagb-columns-stack-mobile .wp-block-uagb-column, .o-tabs__item__content .uagb-columns__inner-wrap.uagb-columns__columns-2.uagb-columns-stack-mobile .wp-block-uagb-column:first-child, .o-tabs__item__content .uagb-columns__inner-wrap.uagb-columns__columns-2.uagb-columns-stack-mobile .wp-block-uagb-column:last-child {
              width: 100%; } }

.avatar-card {
  margin: 90px 75px 15px 15px;
  padding: 10px 15px 30px;
  position: relative;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 8px 20px 0 rgba(1, 1, 1, 0.2); }
  .avatar-card, .avatar-card > .avatar-card__name {
    color: #173764; }
  .avatar-card__img {
    position: absolute;
    right: -75px;
    top: -75px; }
    .avatar-card__img > img {
      border-radius: 50%; }
  .avatar-card > .avatar-card__name {
    font-weight: 600;
    line-height: 25px;
    max-width: calc(100% - 75px);
    margin-bottom: .5rem; }
  .avatar-card > .avatar-card__text {
    margin-bottom: 0; }
  @media (max-width: 767px) {
    .avatar-card {
      margin-left: auto;
      margin-right: auto; } }

.trainer-card {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .trainer-card > .trainer-card__meta {
    flex: 1;
    background: #173764;
    color: #fff;
    padding-bottom: 30px;
    text-align: center; }
    .trainer-card > .trainer-card__meta > * {
      margin-bottom: 0; }
  .trainer-card__meta > .trainer-card__name {
    display: inline-block;
    width: auto;
    padding: 8px 15px;
    font-size: 22px;
    font-weight: 600;
    color: #006eb6;
    background: #fff;
    transform: translateY(-50%);
    margin-bottom: 10px;
    position: relative;
    z-index: 1; }
  .trainer-card__meta > .trainer-card__text {
    padding: 0 2rem 0; }

.trainer-card-list .uagb-column__inner-wrap {
  height: 100%; }

.table-compare table {
  padding-top: 0;
  border-spacing: 10px;
  table-layout: fixed; }
  .table-compare table, .table-compare table th, .table-compare table td {
    border: 0; }
  .table-compare table th, .table-compare table td {
    padding: 10px;
    vertical-align: middle; }
  .table-compare table th.christmas-cell {
    background-image: url("../images/christmas_bg_th.jpg") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    color: #173764; }
  .table-compare table td.christmas-cell {
    background-image: url("../images/christmas_bg_table.jpg") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    color: #173764; }
  .table-compare table ul {
    margin-left: 1.5em; }
    .table-compare table ul.u-p-l {
      margin-left: 2.5em; }
    .table-compare table ul > li:before {
      border-width: 3px;
      margin-left: -1.2em;
      margin-top: .8em; }
  @media screen and (max-width: 767px) {
    .table-compare table,
    .table-compare table tbody,
    .table-compare table tfoot,
    .table-compare table tr, .table-compare table th, .table-compare table td {
      display: block !important;
      width: 100%; }
    .table-compare table thead th,
    .table-compare table td {
      margin-bottom: 10px; }
    .table-compare table thead th {
      display: none !important; }
    .table-compare table td:not(:last-child) {
      margin-bottom: 0; }
    .table-compare table td[data-title]:before {
      content: attr(data-title);
      display: block;
      font-weight: 600;
      background: #214e8d;
      margin-left: -10px;
      margin-top: -10px;
      margin-right: -10px;
      margin-bottom: 15px;
      padding: 10px; } }

.table-compare tr.gold td {
  background: #c1a71c; }

.table-compare th.th-has-icon {
  position: relative;
  padding-top: 50px; }
  .table-compare th.th-has-icon > .icon-ellipse {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%); }

.table-compare th, .table-compare td:first-child {
  background: #173764;
  color: #fff;
  font-size: 26px;
  font-weight: 600; }

.table-compare thead th,
.table-compare tfoot td {
  text-align: center; }
  .table-compare thead th:first-child,
  .table-compare tfoot td:first-child {
    background: none; }

.table-compare td {
  background: #006eb6;
  color: #fff; }

.table-compare tfoot td, .table-compare tfoot .btn {
  background: #3dc33e; }

.table-compare tfoot .btn {
  display: block;
  color: white;
  font-size: 26px;
  font-weight: 600;
  text-decoration: none;
  text-align: center; }

.faq__item {
  margin-bottom: 10px; }
  .faq__item, .faq__item__title, .faq__item__body {
    font-size: 1rem; }
  .faq__item__title {
    margin-bottom: 0 !important;
    padding: 10px 50px 10px 30px;
    background: #006eb6;
    color: #fff !important;
    font-weight: 600 !important;
    cursor: pointer;
    position: relative; }
    .faq__item__title:after {
      content: "";
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url("../images/icons/arrow-down.png");
      background-size: cover;
      width: 22px;
      height: 15px;
      transition: .3s transform ease-in-out; }
  .faq__item__body {
    display: none;
    padding: 20px 30px;
    background: #fff; }
    .faq__item__body > *:last-child {
      margin-bottom: 0; }
  .faq__item.o-accordion--active .faq__item__title:after {
    transform: rotate(180deg) translateY(50%); }

.card {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .card__heading {
    font-size: 26px;
    font-weight: 600;
    background: #006eb6;
    color: white;
    padding: 6px 32px; }
  .card__icon {
    text-align: center;
    margin-top: -50px;
    transform: translateY(50%); }
    .card__icon + .card__heading {
      padding-top: 50px; }
  .card__body {
    padding: 20px 32px 40px;
    background: #006eb6;
    color: white;
    flex: 1; }
  .card__cta {
    display: block;
    width: 100%;
    padding: 6px 32px;
    background: #3dc33e;
    color: white;
    font-size: 16px;
    font-weight: 600;
    position: relative; }
    .card__cta, .card__cta:hover, .card__cta:focus {
      text-decoration: none;
      color: white; }
    .card__cta:hover {
      background: #3aad45; }
    .card__cta--rev {
      text-align: right; }
      .card__cta--rev.card__cta:after {
        right: auto;
        left: 32px;
        transform: translateY(-50%) rotate(135deg); }

.u-m-b-none {
  margin-bottom: 0 !important; }

.u-m-b {
  margin-bottom: 1rem; }

.u-m-b-d {
  margin-bottom: 28px; }

.u-m-t {
  margin-top: auto; }

.cf:after, .clearfix:after, .testimonial:after {
  content: "" !important;
  display: block !important;
  clear: both !important; }

.u-d-f, [class^="wp-block-uagb"] ul.icon-list-flex,
.icon-list-flex, .wp-block-uagb-column.u-d-f .uagb-columns__inner-wrap,
.wp-block-uagb-column.u-d-f .uagb-column__inner-wrap,
.wp-block-uagb-columns.u-d-f .uagb-columns__inner-wrap,
.wp-block-uagb-columns.u-d-f .uagb-column__inner-wrap {
  display: flex; }

.u-fd-c, .wp-block-uagb-column.u-fd-c .uagb-columns__inner-wrap,
.wp-block-uagb-column.u-fd-c .uagb-column__inner-wrap,
.wp-block-uagb-columns.u-fd-c .uagb-columns__inner-wrap,
.wp-block-uagb-columns.u-fd-c .uagb-column__inner-wrap {
  flex-direction: column; }

.u-jc-c, .wp-block-uagb-column.u-jc-c .uagb-columns__inner-wrap,
.wp-block-uagb-column.u-jc-c .uagb-column__inner-wrap,
.wp-block-uagb-columns.u-jc-c .uagb-columns__inner-wrap,
.wp-block-uagb-columns.u-jc-c .uagb-column__inner-wrap {
  justify-content: center; }

.wp-block-uagb-buttons.buttons-arrow .uagb-button__link:after, .card__cta:after, .button.button-icon:after {
  content: "";
  position: absolute;
  right: 32px;
  top: 50%;
  display: inline-block;
  width: 0;
  height: 0;
  padding: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: translateY(-50%) rotate(-45deg); }

.has-text-align-center {
  text-align: center; }

.has-small-font-size {
  font-size: 13px; }

.u-list-bare, [class^="wp-block-uagb"] ul.icon-list-flex,
.icon-list-flex {
  list-style: none; }
  .u-list-bare > li:before, [class^="wp-block-uagb"] ul.icon-list-flex > li:before,
  .icon-list-flex > li:before {
    content: none; }

.is-96x96 img {
  width: 96px;
  height: auto; }

@media screen and (min-width: 768px) {
  .hidden-m {
    display: none; } }

.nf-form-cont .nf-form-content button,
.nf-form-cont .nf-form-content input[type="button"],
.nf-form-cont .nf-form-content input[type="submit"] {
  padding-left: 32px;
  padding-right: 32px;
  background: #006eb6; }
  .nf-form-cont .nf-form-content button:hover,
  .nf-form-cont .nf-form-content input[type="button"]:hover,
  .nf-form-cont .nf-form-content input[type="submit"]:hover {
    color: white;
    background: #004f83; }

.tns-outer .tns-nav {
  margin-top: 32px;
  text-align: left; }
  .tns-outer .tns-nav button {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 5px 6px;
    padding: 0;
    border: 0;
    background: transparent;
    transition: opacity 200ms ease;
    border-radius: 30px;
    border: 2px solid #fff; }
    .tns-outer .tns-nav button:first-child {
      margin-left: 0; }
    .tns-outer .tns-nav button.tns-nav-active {
      background: #fff; }

.simplebar-track.simplebar-track {
  background: #f6f6f6; }
  .simplebar-track.simplebar-track .simplebar-scrollbar:before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    background: #7c8ea6;
    border-radius: 0; }
  .simplebar-track.simplebar-track.simplebar-horizontal {
    height: 18px;
    top: 0; }
    .simplebar-track.simplebar-track.simplebar-horizontal > .simplebar-scrollbar {
      margin-top: 0;
      height: 12px;
      top: 3px; }

.simplebar-wrapper {
  padding-bottom: 20px; }

.simplebar-mask {
  margin-top: 20px; }

.icon-ellipse {
  display: inline-block;
  width: 100px;
  height: 100px;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  position: relative; }
  .icon-ellipse:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: cover;
    width: 68px;
    height: 68px;
    max-width: 70%;
    max-height: 70%; }

.icon-presenter:after {
  background-image: url("../images/icons/presenter.svg"); }

.icon-video:after {
  background-image: url("../images/icons/video.svg"); }

.icon-module:after {
  background-image: url("../images/icons/module.svg"); }

.button.button-icon {
  position: relative;
  margin: auto 0 30px 70px;
  padding-left: 16px;
  white-space: nowrap; }
  .button.button-icon .icon-ellipse {
    position: absolute;
    top: 50%;
    left: -70px;
    width: 80px;
    height: 80px;
    transform: translateY(-50%);
    z-index: 1; }

.icon:before {
  content: "";
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-size: cover;
  margin-right: 1rem;
  vertical-align: top; }

.icon-phone:before {
  background-image: url("../images/icons/phone.svg"); }

.icon-envelope:before {
  background-image: url("../images/icons/envelope.svg"); }

.shepherd-element.shepherd-theme-embis {
  box-shadow: 1px 4px #004f83;
  border: 3px solid #006eb6; }
  .shepherd-element.shepherd-theme-embis .shepherd-arrow:before {
    box-shadow: -1px 1px #006eb6, 2px -1px #fff, 0 4px #004f83; }
  .shepherd-element.shepherd-theme-embis[data-popper-placement^=left] {
    box-shadow: -1px 4px #004f83; }
    .shepherd-element.shepherd-theme-embis[data-popper-placement^=left] > .shepherd-arrow:before {
      box-shadow: 1px -1px #004f83, 0px 1px #fff, 4px 0px #004f83; }
  .shepherd-element.shepherd-theme-embis[data-popper-placement^=bottom] {
    box-shadow: -1px 4px #004f83; }
    .shepherd-element.shepherd-theme-embis[data-popper-placement^=bottom] > .shepherd-arrow:before {
      box-shadow: -2px 1px #006eb6, 1px 1px #fff, -2px -2px #006eb6; }
  .shepherd-element.shepherd-theme-embis .shepherd-header {
    background: #f5f5f5;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .shepherd-element.shepherd-theme-embis .shepherd-title {
    font-weight: 600; }
  .shepherd-element.shepherd-theme-embis .shepherd-cancel-icon {
    line-height: 1; }
    .shepherd-element.shepherd-theme-embis .shepherd-cancel-icon:hover {
      border-color: transparent;
      background: none; }
  .shepherd-element.shepherd-theme-embis .shepherd-button, .shepherd-element.shepherd-theme-embis .shepherd-button:not(:disabled):hover {
    color: #fff; }

.ld-tour-reset {
  text-align: center;
  display: block;
  font-weight: bold;
  border-bottom: 0 !important;
  font-size: 14px;
  box-shadow: none !important;
  transition: right .4s;
  position: fixed;
  z-index: 9000;
  right: -156px;
  background: #eaeaea; }
  @media screen and (min-height: 480px) and (min-width: 768px) {
    .ld-tour-reset {
      top: 314px; } }
  @media screen and (min-height: 720px) and (min-width: 768px) {
    .ld-tour-reset {
      top: 444px; } }
  @media screen and (max-width: 768px) {
    .ld-tour-reset {
      top: auto;
      bottom: 58px; } }
  .ld-tour-reset, .ld-tour-reset:hover {
    color: #585858;
    text-decoration: none;
    cursor: pointer; }
  .ld-tour-reset__icon, .ld-tour-reset__text {
    display: inline-block;
    padding: 13px 19px 13px 19px; }
  .ld-tour-reset__icon {
    background: rgba(0, 0, 0, 0.1); }
  .ld-tour-reset:hover {
    right: 0;
    opacity: 0.8; }

.wp-block-embed {
  margin-bottom: 1em; }

.wp-block-embed figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em; }

.wp-block-embed iframe {
  max-width: 100%; }

.wp-block-embed__wrapper {
  position: relative; }

.wp-embed-responsive .wp-has-aspect-ratio .wp-block-embed__wrapper::before {
  content: "";
  display: block;
  padding-top: 50%; }

.wp-embed-responsive .wp-has-aspect-ratio iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.wp-embed-responsive .wp-embed-aspect-21-9 .wp-block-embed__wrapper::before {
  padding-top: 42.85%; }

.wp-embed-responsive .wp-embed-aspect-18-9 .wp-block-embed__wrapper::before {
  padding-top: 50%; }

.wp-embed-responsive .wp-embed-aspect-16-9 .wp-block-embed__wrapper::before {
  padding-top: 56.25%; }

.wp-embed-responsive .wp-embed-aspect-4-3 .wp-block-embed__wrapper::before {
  padding-top: 75%; }

.wp-embed-responsive .wp-embed-aspect-1-1 .wp-block-embed__wrapper::before {
  padding-top: 100%; }

.wp-embed-responsive .wp-embed-aspect-9-6 .wp-block-embed__wrapper::before {
  padding-top: 66.66%; }

.wp-embed-responsive .wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
  padding-top: 200%; }

.ci_editor .jcrop-holder > div > div {
  border: 2px dashed #e34747; }

.ci_editor .jcrop-vline, .ci_editor .jcrop-hline {
  background: #fff; }

.ci_editor .ci_controls:before {
  position: absolute;
  left: 0;
  top: .5em;
  max-width: 70%;
  color: white;
  font-size: 14px;
  line-height: 1.4;
  content: "Bitte wählen Sie einen Ausschnitt und bestätigen Sie mit dem Haken."; }

.pyro {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1; }

.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards; }

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s; }

@-webkit-keyframes bang {
  to {
    box-shadow: 55px -79px #ffb700, 42px -128px #ff00bb, -23px 36px #000dff, -96px -235px #0062ff, -10px -175px #006fff, 144px 42px #007bff, 115px -202px #ea00ff, -136px -88px #00ff77, -86px -181px #ff0026, 129px -188px #00ffc4, -44px -98px #ff2b00, -53px -48px #ff1500, -80px -105px #ff0048, 82px 23px #ff0073, -114px -69px #4800ff, -73px -189px #ff007b, -24px -130px #ffea00, 19px -63px #40ff00, 49px 12px #ff00d9, 20px -243px #8800ff, -82px 24px #48ff00, 87px -232px #ff00d0, -133px 22px #ff5500, -22px 7px #00ff59, 131px -180px #ff008c, 9px -29px #91ff00, 86px -243px #0051ff, 79px -178px #ff0004, 109px -127px #a2ff00, -22px -80px #ff0037, -8px -63px #00ffc4, -82px -215px #00ff26, 139px -164px #00ff37, -67px -166px #ff0009, 79px 2px #004dff, -9px 20px yellow, 66px 27px #002fff, 141px -115px #000dff, 132px -69px #0033ff, 99px -40px #5900ff, -75px -196px #0037ff, -57px -97px #00fff2, -131px -212px #7bff00, 135px -46px #ddff00, 59px -96px #00fff7, 97px -177px #00c4ff, -76px -74px #62ff00, 37px -106px #1e00ff, 85px -199px #d000ff, -17px -56px #4d00ff, -82px -157px #ffe600; } }

@-moz-keyframes bang {
  to {
    box-shadow: 55px -79px #ffb700, 42px -128px #ff00bb, -23px 36px #000dff, -96px -235px #0062ff, -10px -175px #006fff, 144px 42px #007bff, 115px -202px #ea00ff, -136px -88px #00ff77, -86px -181px #ff0026, 129px -188px #00ffc4, -44px -98px #ff2b00, -53px -48px #ff1500, -80px -105px #ff0048, 82px 23px #ff0073, -114px -69px #4800ff, -73px -189px #ff007b, -24px -130px #ffea00, 19px -63px #40ff00, 49px 12px #ff00d9, 20px -243px #8800ff, -82px 24px #48ff00, 87px -232px #ff00d0, -133px 22px #ff5500, -22px 7px #00ff59, 131px -180px #ff008c, 9px -29px #91ff00, 86px -243px #0051ff, 79px -178px #ff0004, 109px -127px #a2ff00, -22px -80px #ff0037, -8px -63px #00ffc4, -82px -215px #00ff26, 139px -164px #00ff37, -67px -166px #ff0009, 79px 2px #004dff, -9px 20px yellow, 66px 27px #002fff, 141px -115px #000dff, 132px -69px #0033ff, 99px -40px #5900ff, -75px -196px #0037ff, -57px -97px #00fff2, -131px -212px #7bff00, 135px -46px #ddff00, 59px -96px #00fff7, 97px -177px #00c4ff, -76px -74px #62ff00, 37px -106px #1e00ff, 85px -199px #d000ff, -17px -56px #4d00ff, -82px -157px #ffe600; } }

@-o-keyframes bang {
  to {
    box-shadow: 55px -79px #ffb700, 42px -128px #ff00bb, -23px 36px #000dff, -96px -235px #0062ff, -10px -175px #006fff, 144px 42px #007bff, 115px -202px #ea00ff, -136px -88px #00ff77, -86px -181px #ff0026, 129px -188px #00ffc4, -44px -98px #ff2b00, -53px -48px #ff1500, -80px -105px #ff0048, 82px 23px #ff0073, -114px -69px #4800ff, -73px -189px #ff007b, -24px -130px #ffea00, 19px -63px #40ff00, 49px 12px #ff00d9, 20px -243px #8800ff, -82px 24px #48ff00, 87px -232px #ff00d0, -133px 22px #ff5500, -22px 7px #00ff59, 131px -180px #ff008c, 9px -29px #91ff00, 86px -243px #0051ff, 79px -178px #ff0004, 109px -127px #a2ff00, -22px -80px #ff0037, -8px -63px #00ffc4, -82px -215px #00ff26, 139px -164px #00ff37, -67px -166px #ff0009, 79px 2px #004dff, -9px 20px yellow, 66px 27px #002fff, 141px -115px #000dff, 132px -69px #0033ff, 99px -40px #5900ff, -75px -196px #0037ff, -57px -97px #00fff2, -131px -212px #7bff00, 135px -46px #ddff00, 59px -96px #00fff7, 97px -177px #00c4ff, -76px -74px #62ff00, 37px -106px #1e00ff, 85px -199px #d000ff, -17px -56px #4d00ff, -82px -157px #ffe600; } }

@-ms-keyframes bang {
  to {
    box-shadow: 55px -79px #ffb700, 42px -128px #ff00bb, -23px 36px #000dff, -96px -235px #0062ff, -10px -175px #006fff, 144px 42px #007bff, 115px -202px #ea00ff, -136px -88px #00ff77, -86px -181px #ff0026, 129px -188px #00ffc4, -44px -98px #ff2b00, -53px -48px #ff1500, -80px -105px #ff0048, 82px 23px #ff0073, -114px -69px #4800ff, -73px -189px #ff007b, -24px -130px #ffea00, 19px -63px #40ff00, 49px 12px #ff00d9, 20px -243px #8800ff, -82px 24px #48ff00, 87px -232px #ff00d0, -133px 22px #ff5500, -22px 7px #00ff59, 131px -180px #ff008c, 9px -29px #91ff00, 86px -243px #0051ff, 79px -178px #ff0004, 109px -127px #a2ff00, -22px -80px #ff0037, -8px -63px #00ffc4, -82px -215px #00ff26, 139px -164px #00ff37, -67px -166px #ff0009, 79px 2px #004dff, -9px 20px yellow, 66px 27px #002fff, 141px -115px #000dff, 132px -69px #0033ff, 99px -40px #5900ff, -75px -196px #0037ff, -57px -97px #00fff2, -131px -212px #7bff00, 135px -46px #ddff00, 59px -96px #00fff7, 97px -177px #00c4ff, -76px -74px #62ff00, 37px -106px #1e00ff, 85px -199px #d000ff, -17px -56px #4d00ff, -82px -157px #ffe600; } }

@keyframes bang {
  to {
    box-shadow: 55px -79px #ffb700, 42px -128px #ff00bb, -23px 36px #000dff, -96px -235px #0062ff, -10px -175px #006fff, 144px 42px #007bff, 115px -202px #ea00ff, -136px -88px #00ff77, -86px -181px #ff0026, 129px -188px #00ffc4, -44px -98px #ff2b00, -53px -48px #ff1500, -80px -105px #ff0048, 82px 23px #ff0073, -114px -69px #4800ff, -73px -189px #ff007b, -24px -130px #ffea00, 19px -63px #40ff00, 49px 12px #ff00d9, 20px -243px #8800ff, -82px 24px #48ff00, 87px -232px #ff00d0, -133px 22px #ff5500, -22px 7px #00ff59, 131px -180px #ff008c, 9px -29px #91ff00, 86px -243px #0051ff, 79px -178px #ff0004, 109px -127px #a2ff00, -22px -80px #ff0037, -8px -63px #00ffc4, -82px -215px #00ff26, 139px -164px #00ff37, -67px -166px #ff0009, 79px 2px #004dff, -9px 20px yellow, 66px 27px #002fff, 141px -115px #000dff, 132px -69px #0033ff, 99px -40px #5900ff, -75px -196px #0037ff, -57px -97px #00fff2, -131px -212px #7bff00, 135px -46px #ddff00, 59px -96px #00fff7, 97px -177px #00c4ff, -76px -74px #62ff00, 37px -106px #1e00ff, 85px -199px #d000ff, -17px -56px #4d00ff, -82px -157px #ffe600; } }

@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }

@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }

@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }

@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }

@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0; } }

@-webkit-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@-moz-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@-o-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@-ms-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%; }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%; }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%; }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%; }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%; } }

.fadeOut {
  animation: 15s fadeOut;
  animation-fill-mode: forwards; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    visibility: visible;
    opacity: 0; } }
